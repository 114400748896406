
/* BENEFIT_TYPES */
export const GET_BENEFIT_TYPES = "GET_BENEFIT_TYPES"
export const GET_BENEFIT_TYPES_SUCCESS = "GET_BENEFIT_TYPES_SUCCESS"
export const GET_BENEFIT_TYPES_FAIL = "GET_BENEFIT_TYPES_FAIL"

/**
 * add BENEFIT_TYPE
 */
export const ADD_NEW_BENEFIT_TYPE = "ADD_NEW_BENEFIT_TYPE"
export const ADD_BENEFIT_TYPE_SUCCESS = "ADD_BENEFIT_TYPE_SUCCESS"
export const ADD_BENEFIT_TYPE_FAIL = "ADD_BENEFIT_TYPE_FAIL"

/**
 * Edit BENEFIT_TYPE
 */
export const UPDATE_BENEFIT_TYPE = "UPDATE_BENEFIT_TYPE"
export const UPDATE_BENEFIT_TYPE_SUCCESS = "UPDATE_BENEFIT_TYPE_SUCCESS"
export const UPDATE_BENEFIT_TYPE_FAIL = "UPDATE_BENEFIT_TYPE_FAIL"

/**
 * Delete BENEFIT_TYPE
 */
export const DELETE_BENEFIT_TYPE = "DELETE_BENEFIT_TYPE"
export const DELETE_BENEFIT_TYPE_SUCCESS = "DELETE_BENEFIT_TYPE_SUCCESS"
export const DELETE_BENEFIT_TYPE_FAIL = "DELETE_BENEFIT_TYPE_FAIL"

