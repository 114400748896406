import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  NavItem,
  NavLink,
  TabContent,
  FormFeedback,
  TabPane,
  Table,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../Claim/loadingSpinner";
import { useMutation, useQueryClient } from 'react-query';
import { getMemberByIdNumberApi } from "api/member";
import { requestPayment } from "api/transaction";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PaymentAdd = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [idNumber, setIdNumber] = useState(undefined);
  const [mobileNumber, setmobileNumber] = useState(undefined);
  const [amount, setAmount] = useState(undefined);
  const [member, setMember] = useState(null);
  const [search, setSearch] = useState(false);
  const queryClient = useQueryClient();
  const [transactionId, setTransactionId] = useState('');
  const { mutate: mutatePolicies,
    isLoading: isLoadingPolicies, } = useMutation(
      (payload) => getAllPolicyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const onChangeIdNumber = (value) => {
    setMember(null);
    setIdNumber(value);
    setSearch(false);
  };

  const onChangeMobileNumber = (value) => {
    setmobileNumber(value);
  };

  const onChangeAmount = (value) => {
    setAmount(value);
  };

  useEffect(() => {
    mutatePolicies();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNumber: '',
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required("Please National ID Number/Passport"),
    }),
    onSubmit: async (values) => {
      const idNumber = values.idNumber;
      setIdNumber(idNumber);
      console.log(idNumber);
      await getMemberByIdNumberApi(idNumber).then((member) => {
        setMember(member);
        setSearch(true);
      });
    },
  });

  const handleRequestPayment = async () => {
    try {
      await requestPayment({
        userId: member.memberId,
        clientMobile: mobileNumber,
        description: "Sun Jan Premium",
        amount: amount,
        platform: "WEB",
        paymentMethod: "MPESA"
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleConfirmTransaction = () => {
    console.log(`Transaction ID: ${transactionId} confirmed`);
  };

  const stepStyle = {
    borderRadius: '50%',
    border: '2px solid #333',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  };

  //meta title
  document.title = "Add Product | SUN Welfare Administration System";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Payments" breadcrumbItem="New Payment" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Make Payment</h4>
                  <Link to="/payments">
                    <Button type="button" color="primary" className="btn">
                      <i className="bx bx-arrow-back"></i> Back
                    </Button>
                  </Link>
                  <div className="content clearfix">
                    {isLoadingPolicies && <LoadingSpinner />}
                    <Form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="basicpill-pancard-input5">
                              Principal Member National ID Number
                            </Label>
                            <Input
                              id="idNumber"
                              name="idNumber"
                              type="text"
                              className="form-control"
                              placeholder="Enter principal ID number"
                              onChange={(e) => {
                                validation.handleChange(e);
                                onChangeIdNumber(e.target.value);
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.idNumber || ""}
                              invalid={
                                validation.touched.idNumber &&
                                  validation.errors.idNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.idNumber &&
                              validation.errors.idNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.idNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      {idNumber && search && !member && (
                        <h4 className="my-5 fs-5 text text-danger">
                          No member found with this ID Number
                        </h4>
                      )}

                      <Row>
                        <Col lg="4" sm="12" md="8">
                          <Button
                            type="submit"
                            color="primary"
                            block
                            className="mt-4 d-flex align-items-center justify-content-center"
                          >
                            Search Member{" "}
                            <i className="bx bx-search-alt search-icon fs-4 ms-2"></i>
                          </Button>
                        </Col>
                      </Row>

                      {member && (
                        <Row className="mt-5">
                          <Col>
                            <CardTitle className="h4">
                              Principal Member
                            </CardTitle>
                            <p className="card-title-desc">
                              Confirm that this is the member
                              to send payment request to.
                            </p>

                            <div className="table-responsive">
                              <Table className="table mb-0 table-striped">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Full Name</th>
                                    <th>Mobile Number</th>
                                    <th>ID Number</th>
                                    <th>Policy Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>
                                      {`${member.firstName} ${member.middleName || ''
                                        } ${member.lastName}`}
                                    </td>
                                    <td>{member.mobileNumber1}</td>
                                    <td>
                                      {member.identificationNumber}
                                    </td>
                                    <td>{member.status}</td>
                                  </tr>
                                </tbody>
                              </Table>

                              <CardTitle className="h4 mt-4">Send Invoice</CardTitle>
                              <Form>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label for="months">Number of months</Label>
                                      <Input
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter Number of Months"
                                        onChange={(e) => {
                                          validation.handleChange(e);
                                          onChangeAmount(e.target.value);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                          validation.touched.amount &&
                                            validation.errors.amount
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <div className="d-flex justify-content-center">
                                  <Button className="mt-4" color="primary" style={{ width: '50%' }} onClick={handleRequestPayment}>
                                    Send Invoice
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentAdd;