import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  addNewMember as onAddNewMember,
  updateMember as onUpdateMember,
  deleteMember as onDeleteMember,
} from "store/actions";

import {
  MemberId,
  BillingName,
  Date,
  PaymentStatus,
  PolicyStatus
}
  from "./MemberCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import MembersModal from "./MemberModal";
import { useMutation, useQueryClient } from 'react-query';

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllAllMembersApi } from 'api/member';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import { sumArray } from 'utils/tools';
import { getMemberStatusCountsApi } from 'api/report';
import { setMemberSummary } from 'store/actions';
import MainFilter from "components/Common/MainFilter";

function Member() {
  const { isAuthenticated } = useAuth();
  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector, isAdmin, isSuperAdmin, isData, address } = useRole();

  const { wardId, constituencyId, countyId, regionId } = isAuthenticated ? address : {};
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState(isFacilitator ? { wardId } : isSiteCord ? { constituencyId } : isCountyCoordinator ? { countyId } : isClusterLeader ? { regionId } : {})
  const [pages, setPages] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();
  const {
    memberSummary,
  } = useSelector((state) => ({
    memberSummary: state.reports.members,
  }));

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    searchColumns: [
      ...(isFacilitator ? [
        {
          "name": "wardId",
          "value": address?.wardId
        }] : []),
      ...(isCountyCoordinator ? [
        {
          "name": "countyId",
          "value": address?.countyId
        }] : []),
      ...(isClusterLeader ? [
        {
          "name": "regionId",
          "value": address?.regionId
        }] : []),
      ...(isSiteCord ? [
        {
          "name": "constituencyId",
          "value": address?.constituencyId
        }] : [])
    ]
  });

  const { mutate: mutateMemberCounts,
    isLoading: isLoadingProducts,
    data } = useMutation(
      (payload) => getMemberStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setMemberSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateMembers,
    isLoading: isLoadingMembers,
    data: membersData, } = useMutation(
      (payload) => getAllAllMembersApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const membersCardData = [
    { title: "Total Members", iconClass: "bx bx-user", description: memberSummary.length ? sumArray(memberSummary, 'totalMembers').toLocaleString() : 0 },
    { title: "Dependants", iconClass: "bx bx-group", description: memberSummary.length ? sumArray(memberSummary, 'totalDependents').toLocaleString() : 0 },
    { title: "Active Members", iconClass: "bx bx-check-circle", description: memberSummary.length ? sumArray(memberSummary, 'totalActiveMembers').toLocaleString() : 0 },
    { title: "Dormant Members", iconClass: "bx bx-pause-circle", description: memberSummary.length ? sumArray(memberSummary, 'dormantMembers').toLocaleString() : 0 },
    { title: "Default Members", iconClass: "bx bx-error-circle", description: memberSummary.length ? sumArray(memberSummary, 'totalDefaultMembers').toLocaleString() : 0 },
  ];

  //meta title
  document.title = "Members | SUN Welfare Administration System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [member, setMember] = useState(null);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (member && member.firstName) || '',
      middleName: (member && member.middleName) || '',
      lastName: (member && member.lastName) || '',
      memberId: (member && member.memberId) || '',
      billingName: (member && member.billingName) || '',
      memberdate: (member && member.memberdate) || '',
      total: (member && member.total) || '',
      paymentStatus: (member && member.paymentStatus) || 'Paid',
      badgeclass: (member && member.badgeclass) || 'success',
      paymentMethod: (member && member.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter First Name"),
      middleName: Yup.string().required("Please enter Middle Name"),
      lastName: Yup.string().required("Please enter Last Name"),
      memberId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Member Id"
        ).required("Please Enter Your Member Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      memberdate: Yup.string().required("Please Enter Your Member Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateMember = {
          id: member ? member.id : 0,
          firstName: values.firstName,
          memberId: values.memberId,
          billingName: values.billingName,
          memberdate: values.memberdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update member
        dispatch(onUpdateMember(updateMember));
        validation.resetForm();
      } else {
        const newMember = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          firstName: values.firstName,
          memberId: values["memberId"],
          billingName: values["billingName"],
          memberdate: values["memberdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new member
        dispatch(onAddNewMember(newMember));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {
    mutateMembers({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  useEffect(() => {
    mutateMemberCounts({
      fromDate: moment().startOf('month').format('DD-MM-YYYY'),
      toDate: moment().format('DD-MM-YYYY'),
      address: {
        ...addressData
      }
    });
  }, [addressData]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setMember(null);
    } else {
      setModal(true);
    }
  };

  //delete member
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteMember = () => {
    if (member && member.id) {
      dispatch(onDeleteMember(member.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'memberId',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'FULL NAME',
        accessor: 'fullName',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'REGISTRATION DATE',
        accessor: 'joiningDate',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'SHOFCO ID',
        accessor: 'shofcoId',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'NATIONAL ID',
        accessor: 'identificationNumber',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'MOBILE',
        accessor: 'phoneNumber1',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => {
          return <MemberId {...cellProps} />;
        }
      },
      {
        Header: 'COUNTY',
        accessor: 'address.countyName',
        filterable: true,
        Cell: (cellProps) => {
          const wardName = cellProps.value || "N/A";
          return <span>{wardName}</span>;
        }
      },
      {
        Header: 'SUB COUNTY',
        accessor: 'address.constituencyName',
        filterable: true,
        Cell: (cellProps) => {
          const wardName = cellProps.value || "N/A";
          return <span>{wardName}</span>;
        }
      },
      {
        Header: 'WARD',
        accessor: 'address.wardName',
        filterable: true,
        Cell: (cellProps) => {
          const wardName = cellProps.value || "N/A";
          return <span>{wardName}</span>;
        }
      },
      
      
      {
        Header: 'GROUP NAME',
        accessor: 'groupName',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'MEMBER STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'POLICY STATUS',
        accessor: 'policyMatured',
        Cell: (cellProps) => {
          return <PolicyStatus {...cellProps} />;
        }
      },
    ],
    []
  );

  const onChangePagination = (
    { page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters) ? pagination.searchColumns : [
      ...pagination.searchColumns,
      ...(filters?.id ? [
        {
          "name": "id",
          "value": filters?.id
        }] : []),
      ...(filters?.firstName ? [
        {
          "name": "firstName",
          "value": filters?.firstName
        }] : []),
      ...(filters?.lastName ? [
        {
          "name": "lastName",
          "value": filters?.lastName
        }] : []),
      ...(filters?.email ? [
        {
          "name": "email",
          "value": filters?.email
        }] : []),
      ...(filters?.memberId ? [
        {
          "name": "memberId",
          "value": filters?.memberId
        }] : []),
      ...(filters?.shofcoId ? [
        {
          "name": "shofcoId",
          "value": filters?.shofcoId
        }] : []),
      ...(filters?.idNumber ? [
        {
          "name": "idNumber",
          "value": filters?.idNumber
        }] : []),
      ...(filters?.mobileNumber1 ? [
        {
          "name": "mobileNumber1",
          "value": filters?.mobileNumber1
        }] : []),
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const onEdit = row => {
    navigate(`${location.pathname}/${row.memberId}/edit`);
  }

  const handleViewMember = (record) => {
    navigate(`${location.pathname}/${record.memberId}`);
  };

  const onSearch = (data) => {
    const searchColumns = [];
    if (!isEmpty(data)) {
      if (isFacilitator) {
        searchColumns.push({ name: "wardId", value: address?.wardId });
      } else if (isSiteCord && data?.wardId) {
        searchColumns.push(data?.wardId);
      } else if (isCountyCoordinator && data?.wardId) {
        searchColumns.push(data?.countyId);
      } else {
        if ((isClusterLeader || isFinance || isDirector || isAdmin || isSuperAdmin) && data?.regionId) {
          searchColumns.push(data?.regionId);
        }
        if (data.wardId) {
          searchColumns.push(data?.wardId);
        }
        if (data.constituencyId) {
          searchColumns.push(data?.constituencyId);
        }
        if (data.countyId) {
          searchColumns.push(data?.countyId);
        }

      }
      data.startDate && searchColumns.push(data.startDate);
      data.endDate && searchColumns.push(data.endDate);
      let add = {}
      searchColumns.forEach((a, i) => {
        add[a.name] = a.value;
      });
      setAddressData(add);
    }

    setPagination({ ...pagination, searchColumns });
  }

  return (
    <React.Fragment>
      <MembersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteMember}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Members" />
          <Row>
            {/* Reports Render */}
            {membersCardData.map((report, key) => (
              <Col md="" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-small" style={{ fontSize: 10 + 'px' }}>
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <MainFilter pagination={pagination} hasExport={isData || isSuperAdmin || isAdmin || isDirector} pageSize={pages * pagination.pageSize} resource={'um/member'} onSearch={onSearch} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={membersData?.list?.reverse() || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    isPagination={true}
                    filterable={false}
                    hasCreate={!isData}
                    hasSearch
                    showEdit={isAuthenticated && (isData || isSuperAdmin || isAdmin)}
                    showView
                    loading={isLoadingMembers}
                    pages={pages}
                    handleEdit={onEdit}
                    handleView={handleViewMember}
                    iscustomPageSizeOptions={true}
                    addText="Add New Member"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'firstName',
                        text: t('users.firstName'),
                      },
                      {
                        value: 'lastName',
                        text: t('users.lastName'),
                      },
                      {
                        value: 'mobileNumber1',
                        text: t('common.mobileNumber'),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Member" : "Add Member"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Member Id</Label>
                      <Input
                        name="memberId"
                        type="text"
                        placeholder="Insert Member Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.memberId || ""}
                        invalid={
                          validation.touched.memberId && validation.errors.memberId ? true : false
                        }
                      />
                      {validation.touched.memberId && validation.errors.memberId ? (
                        <FormFeedback type="invalid">{validation.errors.memberId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Member Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="memberdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("memberdate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.memberdate}
                      />
                      {validation.touched.memberdate && validation.errors.memberdate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.memberdate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Member.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};

export default Member;
