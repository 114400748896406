import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Col,
  Container,
  Table,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import GroupMembers from "components/members/GroupMembers";
import logo from "../../assets/images/logo-dark2.png";
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRole } from 'hooks/useRole';
import { getGroupTransferByIdApi, groupTransferApproveApi, groupTransferRejectApi, getAllGroupApi } from 'api/group';
import { useNavigate } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { approveApplicationApi } from "api/application";


const GroupTransferDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { address } = useRole();
  const { id } = useParams();

  const { mutate: mutateGroups, isLoading: isLoadingGroups, data: groupsData } = useMutation(
    (payload) => getAllGroupApi(payload),
    {
      onSuccess: res => {
        console.log(res);
      },
      onSettled: () => {
        queryClient.invalidateQueries('get-all-counties');
      },
    }
  );

  const { mutate: mutateGroupMembers,
    data: groupMembers } = useMutation(
      (payload) => getGroupTransferByIdApi(payload),
      {
        onSuccess: res => {
          console.log(res);
          mutateGroupMembers({
            groupId: res?.groupId
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateTransfer,
    data: transferRequestData } = useMutation(
      (payload) => getGroupTransferByIdApi(payload),
      {
        onSuccess: res => {
          console.log(res);
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );


  useEffect(() => {
    mutateTransfer({
      id,
    });
  }, [id]);

  useEffect(() => {
    address?.wardId && mutateGroups({
      pageSize: 2000,
      pageNumber: 0,
      sortBy: "name",
      searchColumns: [{ "name": "wardId", "value": address?.wardId }]
    });
  }, []);


  const handleApprove = async () => {
    try {
      await groupTransferApproveApi({
        transferRequestId: id,
        groupId: transferRequestData?.groupId
      });
      navigate(`/groupTransfers`);
      // setShowPopup(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    try {
      await groupTransferRejectApi({
        transferRequestId: id,
        groupId: transferRequestData?.groupId
      });
      navigate(`/groupTransfers`);
      // setShowPopup(true);
    } catch (error) {
      console.log(error);
    }
  };


  //meta title
  document.title = "Group Transfer Details | SUN Welfare Administration System";


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="GroupTransfer" breadcrumbItem="Group Transfer Details" />
          <Card style={{ maxWidth: "90%", margin: "0 auto" }}>
            <CardBody>
              <Row>
                {/* Logo */}
                <Col md={4}>
                  <img src={logo} alt="Company Logo" style={{ maxWidth: "200px" }} />
                </Col>
                {/* Title and Date */}
                <Col md={4} className="text-center">
                  <h4>GROUP TRANSFER REQUEST</h4>
                  <h5>STATUS : {transferRequestData?.status}</h5>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="text-center mb-4">
                  <div>
                    <h6>Group Information:</h6>
                    <p>Group Name: {transferRequestData?.groupName}</p>
                  </div>
                  <div>
                    <h6>Transfer To:</h6>
                    <h5>{transferRequestData?.toWardName}</h5>
                  </div>
                  <div>
                    <h5>Group Status: {transferRequestData?.status}</h5>
                    <p>Transfer Comments: {transferRequestData?.comments}</p>
                  </div>
                  <Row>
                    <Form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Col md={12} className="text-between">
                        {transferRequestData?.status === 'New' && (
                          <>
                            <Button type="button" color="primary" className="me-4" onClick={handleApprove}>
                              <i className="bx bx-check"></i> Approve
                            </Button>
                            <Button type="button" color="secondary" onClick={handleReject}>
                              <i className="bx bx-x"></i> Reject
                            </Button>
                          </>
                        )}
                      </Col>
                    </Form>
                  </Row>
                </Col>
                <Col md={8} className="text-center mb-4">
                  <GroupMembers groupId={transferRequestData?.groupId} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GroupTransferDetails;
