import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import logo from "../../assets/images/logo-dark2.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { getApplicationByIdApi } from 'api/application';

const ApplicationDetail = () => {

  const { id } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleApprove = (id) => {
    navigate(`/applications/${id}/edit`);
  };

  const handleReject = (id) => {
    navigate(`/applications/${id}/edit`);
  };

  const { mutate: mutateApplication,
    isLoading: isLoadingApplication,
    data: applicationData } = useMutation(
      (payload) => getApplicationByIdApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    mutateApplication({
      applicationId: id
    });

  }, [id])

  //meta title
  document.title = "Application Details | SUN Welfare Administration System";

  if (isLoadingApplication) return <div>Loadding...</div>;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Application" breadcrumbItem="Application Details" />
          <Row>
            <Link to="/applications">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col lg="12">
              <Card style={{ maxWidth: "60%", margin: "0 auto" }}>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Application Details</h4>
                  </div>
                  <Row className="mb-5">
                    <Col className="text-end">
                      {applicationData?.status === 'New' && (
                        <>
                          <Button type="button" color="primary" className="me-4" onClick={() => handleApprove(applicationData?.id)}>
                            <i className="bx bx-check"></i> Approve
                          </Button>
                          <Button type="button" color="primary" onClick={() => handleReject(applicationData?.id)}>
                            <i className="bx bx-check"></i> Reject
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="mb-4">
                        <img src={logo} alt="Company Logo" style={{ maxWidth: "200px" }} />
                      </div>
                    </Col>
                    <Col md="4">
                      <h2 className={applicationData?.status == 'Approved' ? 'text-primary' : applicationData?.status === 'Rejected' ? 'text-danger' : 'text-warning'}>{applicationData?.status}</h2>
                    </Col>
                    <Col md="4">
                      <h6>Application Date</h6>
                      <p>{applicationData?.applicationDate ? moment(applicationData.applicationDate).format("MMM DD, YYYY HH:mm") : "Data not found"}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <dl className="row">
                        <dt className="col-sm-4">Application Number:</dt>
                        <dd className="col-sm-8">{applicationData?.applicationNumber || ''}</dd>

                        <dt className="col-sm-4">Status:</dt>
                        <dd className="col-sm-8">{applicationData?.status}</dd>

                        <dt className="col-sm-4">Application Date:</dt>
                        <dd className="col-sm-8">{applicationData?.applicationDate}</dd>
                      </dl>
                      <h6 className="mt-4">Member Information</h6>
                      <dl className="row">
                        <dt className="col-sm-4">Group Name:</dt>
                        <dd className="col-sm-8">{applicationData?.member?.groupName || 'N/A'}</dd>

                        <dt className="col-sm-4">Identification Number:</dt>
                        <dd className="col-sm-8">{applicationData?.member?.identificationNumber || 'N/A'}</dd>

                        <dt className="col-sm-4">Name:</dt>
                        <dd className="col-sm-8">{`${applicationData?.member?.firstName || ''} ${applicationData?.member?.lastName || ''}`}</dd>

                        <dt className="col-sm-4">Mobile Numbers:</dt>
                        <dd className="col-sm-8">{`${applicationData?.member?.mobileNumber1 || ''}, ${applicationData?.member?.mobileNumber2 || ''}`}</dd>

                        <dt className="col-sm-4">Email:</dt>
                        <dd className="col-sm-8">{applicationData?.member?.email || 'N/A'}</dd>

                        <dt className="col-sm-4">Location:</dt>
                        <dd className="col-sm-8">
                          {`${applicationData?.member?.address?.wardName || ''}, ${applicationData?.member?.address?.constituencyName || ''}, ${applicationData?.member?.address?.countyName || ''}`}
                        </dd>
                      </dl>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApplicationDetail;