import React, { useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import Apaexlinecolumn from "../../AllCharts/apex/apaexlinecolumn"
import LineApexChart from "../../AllCharts/apex/chartapex"
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidget from "./mini-widget";
import { getGroupStatusCountsApi } from 'api/report';
import { setGroupSummary } from 'store/actions'
import { sumArray } from 'utils/tools';

//redux
import { useSelector, useDispatch } from "react-redux";


//Members Chart
const series1 = [
    { name: "Members", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#50a5f1"],
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100],
        },
    },
    tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Dependants Chart
const series2 = [
    { name: "Dependants", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#00b3e4"],
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100],
        },
    },
    tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Defaulted Chart
const series3 = [
    { name: "Defaulted", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#FF5252"],
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100],
        },
    },
    tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

const GroupReports = () => {
    const { isAuthenticated } = useAuth();
    const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector } = useRole(); const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const { wardId, constituencyId, countyId, regionId } = isAuthenticated ? JSON.parse(localStorage.getItem('address')) : {};

    const address = isFacilitator ? { wardId } : isSiteCord ? { constituencyId } : isCountyCoordinator ? { countyId } : isClusterLeader ? { regionId } : {}

    const {
        groupSummary,
    } = useSelector((state) => ({
        groupSummary: state.reports.group,
    }));

    const reports = [
        {
            title: "Active Groups",
            icon: "mdi mdi-account",
            color: "info",
            value: sumArray(groupSummary?.data, 'totalActiveGroups') || 0,
            desc: "Active Groups",
            series: series1,
            options: options1,
            arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
        },
        {
            title: "Total Groups",
            icon: "mdi mdi-account-multiple",
            color: "primary",
            value: sumArray(groupSummary?.data, 'totalGroups') || 0,
            desc: "Total Groups",
            series: series2,
            options: options2,
            arrowUpDown: 'mdi mdi-arrow-down ms-1 text-danger'
        },
        {
            title: "Inactive Groups",
            icon: "mdi mdi-account-alert",
            color: "danger",
            value: sumArray(groupSummary?.data, 'totalInactiveGroups') || 0,
            desc: "Inactive Groups",
            series: series3,
            options: options3,
            arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
        },
    ];

    const series = [
        {
            name: "Active Groups",
            data: sumArray(groupSummary?.data, 'totalActiveGroups') || 0,
        },
        {
            name: "Total Groups",
            data: sumArray(groupSummary?.data, 'totalGroups') || 0,
        },
        {
            name: "Inactive Groups",
            data: sumArray(groupSummary?.data, 'totalInactiveGroups') || 0,
        },
    ];

    const { mutate: mutateGroupCounts,
        data, } = useMutation(
            (payload) => getGroupStatusCountsApi(payload),
            {
                onSuccess: res => {
                    dispatch(setGroupSummary(res));
                },
                onSettled: () => {
                    queryClient.invalidateQueries('get-all-counties');
                },
            }
        );

    useEffect(() => {
        mutateGroupCounts(
            {
                address: {
                    ...address
                }
            }
        );
    }, []);
    console.log("=======>", groupSummary)

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Reports" breadcrumbItem="Group Reports" />
                <Row>
                    <MiniWidget reports={reports} />
                </Row>
                <Row>
                    {/* <Col xl={8}>
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4"> Group Summary </CardTitle>
                                <Apaexlinecolumn dataColors='["--bs-danger","--bs-primary", "--bs-success"]' series={series}   categories={Array.isArray(groupSummary) ? groupSummary.map(summary => summary.monthName) : []} />
                            </CardBody>
                        </Card>
                    </Col> */}
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">New Groups</CardTitle>
                                <LineApexChart dataColors='["--bs-primary", "--bs-success"]' />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default GroupReports
