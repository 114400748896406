import React, { useState, useEffect } from "react"
import moment from "moment";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  FormFeedback,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup";
import LoadingSpinner from "../Claim/loadingSpinner";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from "react-router-dom";
import { getAllRegionsApi, getAllCountiesByRegionApi, getConstituencyApi, getAllWardsApi, getAllRelationshipsApi } from 'api/report';
import { addGroup, groupTransferRequestApi, getGroupByNameApi } from 'api/group';
import { getMemberPolicyApi } from 'api/policy'
import { groupBy } from "lodash"
import Swal from 'sweetalert2';

const AddGroup = () => {

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  const [groupName, setGroupName] = useState(undefined);
  const [search, setSearch] = useState(false);
  const [group, setGroup] = useState(null);
  const [comments, setComments] = useState('');
  const [reason, setReason] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  //meta title
  document.title = "Form Wizard | SUN Welfare Administration System";

  const { mutate: mutatePolicies,
    isLoading: isLoadingPolicies, } = useMutation(
      (payload) => getMemberPolicyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateRegions,
    isLoading: isLoadingRegions,
    data: regionsData, } = useMutation(
      (payload) => getAllRegionsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateCounty,
    isLoading: isLoadingCounty,
    data: countiesData, } = useMutation(
      (payload) => getAllCountiesByRegionApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateSubCounty,
    isLoading: isLoadingSubCounty,
    data: subCountiesData, } = useMutation(
      (payload) => getConstituencyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateWards,
    isLoading: isLoadingWards,
    data: wardsData, } = useMutation(
      (payload) => getAllWardsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );


  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])


  useEffect(() => {
    mutateRegions({ countryId: 1 })
  }, []);

  useEffect(() => {
    mutatePolicies();
  }, []);

  const validationGroupName = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: '',
    },
    validationSchema: Yup.object({
      groupName: Yup.string().required("Please Enter Group Name"),
    }),
    onSubmit: async (values) => {
      const groupName = values.groupName;
      setGroupName(groupName);
      await getGroupByNameApi(groupName).then((g) => {
        console.log(g);
        setGroup(g);
        setSearch(true);
      });
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: '',
      regionId: '',
      countyId: '',
      constituencyId: '',
      wardId: '',
    },
    validationSchema: Yup.object({
      comments: Yup.string().required("Please Enter Comments"),
      regionId: Yup.string().required("Please Select Region"),
      countyId: Yup.string().required("Please Select County"),
      constituencyId: Yup.string().required("Please Select SubCounty"),
      wardId: Yup.string().required("Please Select Ward"),
    }),
    onSubmit: async (values) => {
      let transferData = {
        fromWardId: group?.address?.wardId,
        toWardId: values.wardId,
        groupId: group?.id,
        comments: values.comments,
      }
      const res = await groupTransferRequestApi(transferData)
      if (res.status === "Success") {
        Swal.fire({
          icon: 'success',
          title: 'Request Initiated Successfully',
          showConfirmButton: true,
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/groupTransfers');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again.',
        });
      }
    },
  });

  const handleRegionChange = e => {
    const regionId = e.target.value;
    validation.setFieldValue('regionId', regionId);
    mutateCounty({
      regionId
    });
  }

  const handleCountyChange = e => {
    const countyId = e.target.value;
    validation.setFieldValue('countyId', countyId);
    mutateSubCounty({
      countyId
    });
  }

  const handleSubCountyChange = e => {
    const constituencyId = e.target.value;
    validation.setFieldValue('constituencyId', constituencyId);
    mutateWards({
      constituencyId
    });
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate('/groupTransfers')
  };

  const onChangeGroupName = (value) => {
    setGroup(null);
    setGroupName(value);
    setSearch(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="GroupTransfers" breadcrumbItem="New Group Transfer" />

          <Row>
            <Link to="/groupTransfers">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Initiate New Group Transfer</h4>
                  </div>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Group Transfer
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <Form
                            autoComplete="off"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validationGroupName.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-pancard-input5">
                                    Group Name
                                  </Label>
                                  <Input
                                    id="groupName"
                                    name="groupName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Group Name"
                                    onChange={(e) => {
                                      validationGroupName.handleChange(e);
                                      onChangeGroupName(e.target.value);
                                    }}
                                    onBlur={validationGroupName.handleBlur}
                                    value={validationGroupName.values.groupName || ""}
                                    invalid={
                                      validationGroupName.touched.groupName &&
                                        validationGroupName.errors.groupName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationGroupName.touched.idNumber &&
                                    validationGroupName.errors.idNumber ? (
                                    <FormFeedback type="invalid">
                                      {validationGroupName.errors.idNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {groupName && search && !group && (
                              <h4 className="my-5 fs-5 text text-danger">
                                No Group found with this Name
                              </h4>
                            )}

                            <Row>
                              <Col lg="4" sm="12" md="8">
                                <Button
                                  type="submit"
                                  color="primary"
                                  block
                                  className="mt-4 d-flex align-items-center justify-content-center"
                                >
                                  Search Group{" "}
                                  <i className="bx bx-search-alt search-icon fs-4 ms-2"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Form>

                          {group && (
                            <Row className="mt-5">
                              <Col>
                                <CardTitle className="h4">
                                  Group
                                </CardTitle>
                                <p className="card-title-desc">
                                  Confirm that this is the group
                                  to transfer
                                </p>

                                <div className="table-responsive">
                                  <Table className="table mb-0 table-striped">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Group Name</th>
                                        <th>Group Number</th>
                                        <th>Ward</th>
                                        <th>Status</th>
                                        <th>Created On</th>
                                        <th>Total Members</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>
                                          {group?.name || ''}
                                        </td>
                                        <td>{group?.groupNumber}</td>
                                        <td>
                                          {group?.address?.wardName}
                                        </td>
                                        <td>{group?.status}</td>
                                        <td>{moment(group?.createdDate).format('YYYY MMM DD h:mm A')}</td>
                                        <td>{group?.totalMembers}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <CardTitle className="h3 mt-5">
                                    Destination Details
                                  </CardTitle>
                                  <Form
                                    autoComplete="off"
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                    }}
                                  >
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="regionId">Select Region</Label>
                                          <Input
                                            name="regionId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select Region"
                                            onChange={handleRegionChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.regionId || ""}
                                            invalid={
                                              validation.touched.regionId && validation.errors.regionId ? true : false
                                            }
                                          >
                                            <option key={''}>Select Region</option>
                                            {regionsData && regionsData.map(({ id, name }) =>
                                              <option value={id} key={id}>{name}</option>
                                            )}
                                          </Input>
                                          {validation.touched.regionId && validation.errors.regionId ? (
                                            <FormFeedback type="invalid">{validation.errors.regionId}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="countyId">Select County</Label>
                                          <Input
                                            name="countyId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select County"
                                            onChange={handleCountyChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.countyId || ""}
                                            invalid={
                                              validation.touched.countyId && validation.errors.countyId ? true : false
                                            }
                                          >
                                            <option key={''}>Select County</option>
                                            {countiesData && countiesData.map(({ id, name }) =>
                                              <option value={id} key={id}>{name}</option>
                                            )}
                                          </Input>
                                          {validation.touched.countyId && validation.errors.countyId ? (
                                            <FormFeedback type="invalid">{validation.errors.countyId}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="constituencyId">Select SubCounty</Label>
                                          <Input
                                            name="constituencyId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select SubCounty"
                                            onChange={handleSubCountyChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.constituencyId || ""}
                                            invalid={
                                              validation.touched.constituencyId && validation.errors.constituencyId ? true : false
                                            }
                                          >
                                            <option key={''}>Select Sub-County</option>
                                            {subCountiesData && subCountiesData.map(({ id, name }) =>
                                              <option value={id} key={id}>{name}</option>
                                            )}
                                          </Input>
                                          {validation.touched.constituencyId && validation.errors.constituencyId ? (
                                            <FormFeedback type="invalid">{validation.errors.constituencyId}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <Label htmlFor="wardId">Select Ward</Label>
                                          <Input
                                            name="wardId"
                                            type="select"
                                            className="select2"
                                            placeholder="Select Ward"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.wardId || ""}
                                            invalid={
                                              validation.touched.wardId && validation.errors.wardId ? true : false
                                            }
                                          >
                                            <option key={''}>Select Ward</option>
                                            {wardsData && wardsData.map(({ id, name }) =>
                                              <option value={id} key={id}>{name}</option>
                                            )}
                                          </Input>
                                          {validation.touched.wardId && validation.errors.wardId ? (
                                            <FormFeedback type="invalid">{validation.errors.wardId}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="12">
                                        <div className="mb-3">
                                          <FormGroup>
                                            <Label for="comments">Comments</Label>
                                            <Input
                                              name="comments"
                                              type="text"
                                              className="form-control"
                                              id="comments"
                                              placeholder="Enter Comments"
                                              value={validation.values.comments}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={validation.touched.comments && validation.errors.comments}
                                            />
                                            {validation.touched.comments && validation.errors.comments && (
                                              <FormFeedback>{validation.errors.comments}</FormFeedback>
                                            )}
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="text-end">
                                      <Col lg="12">
                                        <Button color="primary" type="submit">Initiate Transfer</Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                  <Modal isOpen={showPopup} toggle={handleClosePopup}>
                                    <ModalBody>
                                      Request Initiated Successfully.
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="primary" onClick={handleClosePopup}>
                                        OK
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddGroup
