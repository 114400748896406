import React, { useState, useEffect } from "react";
import ClaimStatusModal from './ClaimStatusModal'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  FormFeedback,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from 'formik';
import { isEmpty } from 'lodash';
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from "react-redux";
import { getMemberByIdNumberApi, getNextOfKinApi, getDependantsApi } from "api/member";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { addClaim, uploadMedia } from 'api/claim'
import { getAllPolicyApi } from 'api/policy';
import LoadingSpinner from "../Claim/loadingSpinner";
import {
  setMember
} from "store/actions";


const FormWizard = () => {
  document.title = "Form Wizard | SUN Welfare Administration System";

  const [activeTab, setactiveTab] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1])
  const [deathDate, setDeathDate] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [idNumber, setIdNumber] = useState(undefined);
  const [modal2, setModal2] = useState(false);
  const { member } = useSelector((state) => ({ member: state.member.member }));
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [claimError, setClaimError] = useState('');

  const [claimAttatchments, setClaimAttatchments] = useState({
    burialPermit: { file: {}, type: 'BurialPermit' },
    chiefLetter: { file: {}, type: 'LetterFromChief' },
    birthCert: { file: {}, type: 'BirthCertificate' },
    minutes: { file: {}, type: 'GroupMinutes' },
    grpRep1Id: { file: {}, type: 'GroupRep1Id' },
    grpRep2Id: { file: {}, type: 'GroupRep2Id' },
    familyRepId: { file: {}, type: 'FamilyRepId' },
    nextOfKinId: { file: {}, type: 'IDNumber' }
  });

  const [deceased, setDeceased] = useState(null);
  const queryClient = useQueryClient();

  const [dependentsList, setDependentsList] = useState([]);

  const { mutate: mutatePolicies,
    isLoading: isLoadingPolicies,
    data: policiesData, } = useMutation(
      (payload) => getAllPolicyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateDependants,
    isLoading: isLoadingDependants,
    data: dependantsData, } = useMutation(
      (payload) => getDependantsApi(payload),
      {
        onSuccess: (data) => {
          member['relationship'] = 'Principal Member';
          setDependentsList([member, ...data]);
        }
        ,
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateNextOfKin,
    isLoading: isLoadingNextOfKin,
    data: nextOfKinData, } = useMutation(
      (payload) => getNextOfKinApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const onChangeIdNumber = value => {
    setMember(null);
    setIdNumber(value);
    setSearch(false);
  }

  useEffect(() => {
    mutatePolicies();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNumber: '',
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required("Please National ID Number/Passport"),
    }),
    onSubmit: async (values) => {
      const idNumber = values.idNumber;
      setIdNumber(idNumber)
      await getMemberByIdNumberApi(idNumber).then(async (m) => {
        await dispatch(setMember(m));
        if (m) {
          mutateNextOfKin({ memberId: m.memberId });
          mutateDependants({ memberId: m.memberId });
        }

        m?.status === "Pending" && setClaimError('You cant create a claim for this member because policy is not active.');
        console.log(m?.policyMatured, m?.status === "Active");

        if (m?.status === "Active" && !m?.policyMatured) {
          setClaimError('You cant create a claim for this member because policy has not matured.');
        }
        setSearch(true);
      });
    },
  });

  const documentsValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      deathDate: '',
      burialPermit: '',
      chiefLetter: '',
      birthCert: '',
      minutes: '',
      grpRep1Id: '',
      grpRep2Id: '',
      familyRepId: '',
      nextOfKinId: ''
    },
    validationSchema: Yup.object({
      deathDate: Yup.string().required("Please select date of death"),
      burialPermit: Yup.string().required("Burial Permit is required"),
      chiefLetter: Yup.string().required("Letter From Chief is required"),
      birthCert: Yup.string().required("Birth Certificate is required"),
      minutes: Yup.string().required("Meeting Minutes is required"),
      grpRep1Id: Yup.string().required("Group Representative 1 ID is required"),
      grpRep2Id: Yup.string().required("Group Representative 2 ID is required"),
      familyRepId: Yup.string().required("Family Member ID is required"),
      nextOfKinId: Yup.string().required("Next of Kin ID is required")
    }),
    onSubmit: async ({ deathDate, ...values }) => {
      console.log(deathDate, values);
      handleCreateClaim();
    }
  });

  const handleCreateClaim = async () => {
    try {
      const policy = policiesData && policiesData.find(p => p.policyNumber === 'WELF-202311263');
      const claimResponse = await addClaim({
        policyId: policy?.id,
        memberId: member.memberId,
        type: "Dependent",
        burialPermitSerialNumber: serialNumber,
        deathCertificate: {
          firstName: deceased.firstName,
          deathDate: deathDate,
          dependentId: member?.memberId
        }
      });

      const claimId = claimResponse.data;
      let actions = [];
      Object.entries(claimAttatchments).forEach(([key, value]) => {
        const { file, type } = value;
        actions.push(uploadMedia(claimId, file, type));
      });

      await Promise.all(actions);

      toggleTab(activeTab + 1);
    } catch (error) {
      console.error('Error creating claim:', error);
    }
  }

  const handleRadioChange = index => {
    const data = dependentsList[index];
    setDeceased(data);
  }

  const handleFileChange = (e) => {
    const key = e.target.name;
    console.log(e?.target.files[0])
    setClaimAttatchments({ ...claimAttatchments, [key]: { ...claimAttatchments[key], file: e.target.files[0] } });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Claims" breadcrumbItem="New Claim" />
          <Row>
            <Link to="/claims">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Make A Claim</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Details
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">2.</span> Upload Documents
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">3.</span> Confirm Details
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <Form autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-pancard-input5">
                                    Principal Member National ID Number
                                  </Label>
                                  <Input
                                    id="idNumber"
                                    name="idNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter principal ID number"
                                    onChange={(e) => {
                                      validation.handleChange(e);
                                      onChangeIdNumber(e.target.value);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.idNumber || ""}
                                    invalid={
                                      validation.touched.idNumber && validation.errors.idNumber ? true : false
                                    }
                                  />
                                  {validation.touched.idNumber && validation.errors.idNumber ? (
                                    <FormFeedback type="invalid">{validation.errors.idNumber}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {idNumber && search && !member && (
                              <h4 className="my-5  fs-5 text text-danger">
                                No member found with this ID Number
                              </h4>
                            )}

                            <Row>
                              <Col lg="2" sm="12" md="8">
                                <Button
                                  type="submit"
                                  color="primary"
                                  block
                                  className="mt-4 d-flex align-items-center justify-content-center"
                                >
                                  Search Claimant{" "}
                                  <i className="bx bx-search-alt search-icon fs-4 ms-2"></i>
                                </Button>
                              </Col>
                            </Row>

                            {!isEmpty(member) && (
                              <Row className="mt-5">
                                <Col>
                                  <CardTitle className="h4">Principal Member</CardTitle>
                                  <p className="card-title-desc">
                                    Confirm that this is the principle member under which a claim is being raised.
                                  </p>

                                  <div className="table-responsive">
                                    <Table className="table mb-0 table-striped">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Full Name</th>
                                          <th>Mobile Number</th>
                                          <th>ID Number</th>
                                          <th>Member Status</th>
                                          <th>Policy Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">1</th>
                                          <td>{`${member.firstName} ${member.middleName || ''} ${member.lastName}`}</td>
                                          <td>
                                            {member.mobileNumber1}
                                          </td>
                                          <td>{member.identificationNumber}</td>
                                          <td>{member.status}</td>
                                          <td>{member.policyMatured ? "Matured" : "Not Matured"}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                  {nextOfKinData && nextOfKinData[0] && <>
                                    <CardTitle className="h4">Next Of Kin</CardTitle>
                                    <p className="card-title-desc">
                                      Confirm next of kin
                                    </p>

                                    <div className="table-responsive">
                                      <Table className="table mb-0 table-striped">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Full Name</th>
                                            <th>Mobile Number</th>
                                            <th>Gender</th>
                                            <th>DOB</th>
                                            <th>Designation</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="row">1</th>
                                            <td>{`${nextOfKinData[0].firstName || ''} ${nextOfKinData[0].middleName || ''} ${nextOfKinData[0].lastName || ''}`}</td>
                                            <td>
                                              {nextOfKinData[0].mobileNumber}
                                            </td>
                                            <td>{nextOfKinData[0].gender}</td>
                                            <td>{nextOfKinData[0].dob}</td>
                                            <td>
                                              {nextOfKinData[0].relationship}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </>
                                  }
                                  <CardTitle className="h4 mt-5">Dependants</CardTitle>
                                  <p className="card-title-desc">
                                    Select deceased from member list
                                  </p>
                                  <div className="table-responsive">
                                    <Table className="table mb-0 table-striped">
                                      <thead>
                                        <tr>
                                          <th>Select</th>
                                          <th>#</th>
                                          <th>Full Name</th>
                                          <th>Gender</th>
                                          <th>Designation</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dependentsList &&
                                          dependentsList.map((d, index) =>
                                            d && (
                                              <tr key={JSON.stringify(d)}>
                                                <td>
                                                  <FormGroup check>
                                                    <Input type="radio" name='dep' onChange={() => handleRadioChange(index)} />{"  "}
                                                  </FormGroup>
                                                </td>
                                                <th scope="row">{index + 1}</th>
                                                <td>{`${d.firstName || ''} ${d.middleName || ''} ${d.lastName || ''}`}</td>
                                                <td>{d && (d.gender || d.birthCertificationNumber) ? `${d.gender ? d.gender : ''} ${d.birthCertificationNumber ? `(${d.birthCertificationNumber})` : ''}` : ''}</td>
                                                <td>{d && d.relationship ? d.relationship : ''}</td>
                                              </tr>
                                            )
                                          )
                                        }
                                        {
                                          !dependentsList && <tr>
                                            <td colSpan="5">No dependents found for this member.</td>
                                          </tr>
                                        }
                                      </tbody>
                                    </Table>
                                  </div>
                                  <h6 className="mt-3 text-muted">Select deceased from member list</h6>
                                </Col>
                              </Row>
                            )}
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <div>
                            <Form onSubmit={documentsValidation.handleSubmit}>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label for="deathDate">
                                      Date Of death
                                    </Label>
                                    <Input
                                      name="deathDate"
                                      type="date"
                                      className="form-control"
                                      id="deathDate"
                                      placeholder="Select Date of Death"
                                      onChange={(e) => { setDeathDate(e.target.value); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.deathDate && documentsValidation.errors.deathDate ? true : false
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label for="deathDate">
                                      Burial Permit Serial Number
                                    </Label>
                                    <Input
                                      name="serialNumber"
                                      type="number"
                                      className="form-control"
                                      id="serialNumber"
                                      placeholder="Enter Burial Permit Serial Number"
                                      onChange={(e) => setSerialNumber(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="burialPermit">
                                      Burial Permit
                                    </Label>
                                    <Input
                                      type="file"
                                      className="form-control"
                                      name="burialPermit"
                                      id="burialPermit"
                                      placeholder="Select Burial Permit"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.burialPermit && documentsValidation.errors.burialPermit ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.burialPermit && documentsValidation.errors.burialPermit ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.burialPermit}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="munites">
                                      Meeting Minutes
                                    </Label>
                                    <Input
                                      name="minutes"
                                      type="file"
                                      className="form-control"
                                      id="minutes"
                                      placeholder="Enter Last Name"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.minutes && documentsValidation.errors.minutes ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.minutes && documentsValidation.errors.minutes ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.minutes}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="chiefLetter">
                                      Letter From Chief
                                    </Label>
                                    <Input
                                      type="file"
                                      name="chiefLetter"
                                      className="form-control"
                                      id="chiefLetter"
                                      placeholder="Upload Family Member National ID Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.chiefLetter && documentsValidation.errors.chiefLetter ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.chiefLetter && documentsValidation.errors.chiefLetter ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.chiefLetter}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="birthCert">
                                      Birth Certificate
                                    </Label>
                                    <Input
                                      name="birthCert"
                                      type="file"
                                      className="form-control"
                                      id="birthCert"
                                      placeholder="Enter Family Member Mobile Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.birthCert && documentsValidation.errors.birthCert ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.birthCert && documentsValidation.errors.birthCert ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.birthCert}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="grpRep1Id">
                                      Group Representative 1 ID
                                    </Label>
                                    <Input
                                      type="file"
                                      name="grpRep1Id"
                                      className="form-control"
                                      id="grpRep1Id"
                                      placeholder="Enter Family Member Mobile Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.grpRep1Id && documentsValidation.errors.grpRep1Id ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.grpRep1Id && documentsValidation.errors.grpRep1Id ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.grpRep1Id}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="grpRep2Id">
                                      Group Representative 2 ID
                                    </Label>
                                    <Input
                                      type="file"
                                      name="grpRep2Id"
                                      className="form-control"
                                      id="grpRep2Id"
                                      placeholder="Enter Family Member Mobile Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.grpRep2Id && documentsValidation.errors.grpRep2Id ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.grpRep2Id && documentsValidation.errors.grpRep2Id ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.grpRep2Id}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="familyRepId">
                                      Family Member ID
                                    </Label>
                                    <Input
                                      type="file"
                                      name="familyRepId"
                                      className="form-control"
                                      id="familyRepId"
                                      placeholder="Enter Family Member Mobile Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.familyRepId && documentsValidation.errors.familyRepId ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.familyRepId && documentsValidation.errors.familyRepId ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.familyRepId}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="nextOfKinId">
                                      Next of Kin ID
                                    </Label>
                                    <Input
                                      name="nextOfKinId"
                                      type="file"
                                      className="form-control"
                                      id="nextOfKinId"
                                      placeholder="Enter Family Member Mobile Number"
                                      onChange={(e) => { handleFileChange(e); documentsValidation.handleChange(e); }}
                                      onBlur={documentsValidation.handleBlur}
                                      invalid={
                                        documentsValidation.touched.nextOfKinId && documentsValidation.errors.nextOfKinId ? true : false
                                      }
                                    />
                                    {documentsValidation.touched.nextOfKinId && documentsValidation.errors.nextOfKinId ? (
                                      <FormFeedback type="invalid">{documentsValidation.errors.nextOfKinId}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Submit Detail</h5>
                                  <p className="text-muted">
                                    Claim Successfully Submitted!
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={((activeTab === 3 || !member) || (!deceased && activeTab === 1)) ? "next disabled" : "next"}
                        >
                          {/* <Link
                            to="#"
                            onClick={activeTab === 2 ? () => handleCreateClaim() : () => toggleTab(activeTab + 1)
                            }
                          >
                            {activeTab === 2 ? 'Submit' : 'Next'}
                          </Link> */}
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              if (activeTab === 2) {
                                documentsValidation.handleSubmit();
                                // if (documentsValidation.isValid
                                //   && documentsValidation.values.burialPermit
                                //   && documentsValidation.values.burialPermitSerialNumber
                                //   && documentsValidation.values.chiefLetter
                                //   && documentsValidation.values.familyRepId
                                //   && documentsValidation.values.grpRep1Id
                                //   && documentsValidation.values.grpRep2Id
                                //   && documentsValidation.values.nextOfKinId
                                //   && documentsValidation.values
                                // ) {
                                //   // try {
                                //   //   handleCreateClaim();
                                //   // } catch (error) {
                                //   //   console.error('Error in handleCreateClaim:', error);
                                //   // }
                                // }
                              } else {
                                toggleTab(activeTab + 1);
                              }
                            }}
                            disabled={(activeTab === 1 && member && member.status !== "Active")}
                          >
                            {activeTab === 2 ? 'Submit' : 'Next'}
                          </Button>
                        </li>
                      </ul>
                      {activeTab === 1 && member && (
                        <span className="text-danger ml-2">{claimError}</span>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ClaimStatusModal
        show={modal2 && member}
        member={member || {}}
        onCloseClick={() => setModal2(false)}
      />
    </React.Fragment>
  )
}

export default FormWizard