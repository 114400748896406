import common from './common.json';
import members from './members.json';
import groups from './groups.json';
import communications from './communications.json';
import products from './products.json';
import transactions from './transactions.json';
import benefitTypes from './benefitTypes.json';
import applications from './applications.json';
import claims from './claims.json';
import payments from './payments.json';
import categories from './categories.json';
import riskClass from './riskClass.json';
import users from './users.json';
import reports from './reports.json';
import roles from './roles.json';
import covers from './covers.json';
import invoices from './invoices.json';
import benefits from './benefits.json';
import receipts from './receipts.json';
import translation from './translation.json';
import address from './address.json';

export default {
    ...members,
    ...communications,
    ...products,
    ...groups,
    ...receipts,
    ...benefitTypes,
    ...applications,
    ...claims,
    ...categories,
    ...payments,
    ...reports,
    ...riskClass,
    ...roles,
    ...users,
    ...covers,
    ...transactions,
    ...invoices,
    ...benefits,
    ...common,
    ...translation,
    ...address,
};