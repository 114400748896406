import React, { useEffect, useMemo, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Button } from "reactstrap";
import { useMutation, useQueryClient } from 'react-query';
import { getAllRegionsApi, getAllCountiesApi, getConstituencyApi, getAllWardsApi, getAllRelationshipsApi } from 'api/report';
import { getAllGroupApi } from 'api/group';
import { getMemberByIdApi } from 'api/member';
import { getApplicationApi, approveApplicationApi, rejectApplicationApi } from "api/application";
import MemberDetailsTab from '../Members/wizard/MemberDetailsTab';
import AddressTap from "../Members/wizard/AddressTab";
import AddNextOfKin from "../Members/wizard/AddNextOfKin";
import AddDependants from "../Members/wizard/AddDependant";
import { setMember, editMember } from "store/actions";
import CustomModal from '../../components/CommonForBoth/customModal';
import Swal from 'sweetalert2';

const FormWizard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [modalContent, setModalContent] = useState({}); // State for modal content
  const [nextOfKin, setNextOfKin] = useState(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const {
    appMember,
  } = useSelector((state) => ({
    appMember: state.member.appMember
  }));

  //meta title
  document.title = "Form Wizard | SUN Welfare Administration System";

  const [activeTab, setactiveTab] = useState(1)
  const [memberId, setMemberId] = useState(null);
  const [passedSteps, setPassedSteps] = useState([1])
  const [approvalStatus, setApprovalStatus] = useState("")
  const [comments, setComments] = useState('');
  const queryClient = useQueryClient();
  const [address, setAddress] = useState({});
  const [memberData, setMemberData] = useState({})

  const { mutate: mutateApplication,
    isLoading: isLoadingApplication,
    data: application } = useMutation(
      (payload) => getApplicationApi(payload),
      {
        onSuccess: app => {
          onChangeMemberId(app.member.memberId)
          dispatch(setMember(app?.member));
          if (app.member.address) {
            for (const key of Object.keys(app.member.address)) {
              dispatch(editMember({ [key]: app.member.address[key] }, 'address'))
            }
          }
          if (app.member.dependents.length) {
            dispatch(editMember(app.member.dependents, 'dependant'));
          }
          if (app.member.nextOfKin) {
            for (const key of Object.keys(app.member.nextOfKin)) {
              dispatch(editMember({ [key]: app.member.nextOfKin[key] }, 'nextOfKin'))
            }
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateNextOfKin,
    isLoading: isLoadingNextOfKins,
    data: nextOfKins } = useMutation(
      (payload) => getNextOfKinByIdApi(payload),
      {
        onSuccess: res => {
          console.log(res);
          if (res?.length > 0) {
            const kin = res?.find(k => k.status == "ACTIVE")
            setNextOfKin(kin);
            kin?.nextOfKinId && mutateAttachements(kin?.nextOfKinId)
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries('/um/nextofkin');
        },
      }
    );


  const { mutate: mutateRelationships,
    isLoading: isLoadingRelationships,
    data: relationshipsData, } = useMutation(
      (payload) => getAllRelationshipsApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    mutateApplication({
      applicationId: id
    });
    // mutateRelationships();

  }, [id])

  const handleRadioChange = (event) => {
    setApprovalStatus(event.target.value)
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleApproveMember = async () => {
    try {
      let message = '';
      if (approvalStatus === "approve") {
        await approveApplicationApi({ applicationId: Number(id), comments: comments });
        message = 'Application Approved';
      } else {
        await rejectApplicationApi({ applicationId: Number(id), comments: comments });
        message = 'Application Rejected';
      }
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: message,
        confirmButtonText: 'OK',
      }).then(() => {
        // Redirect or perform another action after confirmation
        navigate('/applications');
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        confirmButtonText: 'OK',
      });
      console.log(err);
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const { mutate: mutateMember,
    isLoading: isLoadingMember,
    data: member } = useMutation(
      (payload) => getMemberByIdApi(payload),
      {
        onSuccess: res => {
        },
        onSettled: () => {
          queryClient.invalidateQueries('/um/member');
        },
      }
    );

  useEffect(() => {
    memberId && mutateMember(
      memberId
    );

  }, [memberId])

  const onChange = (e, key) => {
    console.log(key)
    const value = e.target.value;
    if (key === 'nextOfKin') {
      dispatch(editMember({ [e.target.name]: value }, 'nextOfKin'))
    } else if (key === 'address') {
      dispatch(editMember({ [e.target.name]: value }, 'address'))
    } else {
      dispatch(editMember({ [e.target.name]: value }, 'member'))
    }
  }

  const onGoNext = () => {
    toggleTab(activeTab + 1);
  }

  const onChangeAddress = ({ groupId, ...values }) => {
    setAddress({ ...address, ...values });
    if (groupId) {
      setMember({ ...memberData, groupId });
    }
  }

  const onChangeMemberId = id => {
    id && setMemberId(id);
  }

  return (
    <React.Fragment>
      <CustomModal
        isOpen={modalOpen}
        toggle={toggleModal}
        title={modalContent.title}
        message={modalContent.message}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Application" breadcrumbItem="Approve Application" />
          <Row>
            <Link to="/applications">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Approve Member Application</h4>
                    <Link to="/applications">
                      <Button type="button" color="primary" className="btn">
                        <i className="bx bx-arrow-back"></i> Back
                      </Button>
                    </Link>
                  </div>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        {/* <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Region
                          </NavLink>
                        </NavItem> */}
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">2.</span> Next Of Kin Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">3.</span> Dependant Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">4.</span> Approve
                          </NavLink>
                        </NavItem>

                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <MemberDetailsTab onNext={onGoNext} onChangeAddress={onChangeAddress} onChangeMemberId={onChangeMemberId} member={member} />
                        </TabPane>
                        <TabPane tabId={2}>
                          <AddNextOfKin onChange={onChange} member={application?.member || member} relationshipsData={relationshipsData} kin={nextOfKin} setKin={setNextOfKin} onNext={onGoNext} />
                        </TabPane>
                        <TabPane tabId={3}>
                          <AddDependants onChange={onChange} member={application?.member || member} relationshipsData={relationshipsData} onNext={onGoNext} />
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Approve/Reject Application</h5>
                                </div>
                              </div>
                            </Col>
                          </div>
                          <div className="row justify-content-center mt-4">
                            <Col lg="6">
                              <Row>
                                <div className="text-center">
                                  <label>
                                    <input
                                      type="radio"
                                      name="radioOption"
                                      value="approve"
                                      onChange={handleRadioChange}
                                    />
                                    Approve Application
                                  </label>
                                </div>
                              </Row>
                              <Row>
                                <div className="text-center">
                                  <label className="ml-3">
                                    <input
                                      type="radio"
                                      name="radioOption"
                                      value="reject"
                                      onChange={handleRadioChange}
                                    />
                                    Reject Application
                                  </label>
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <div className="text-center">
                                  <Label for="comments">Comments:</Label>
                                  <Input
                                    type="textarea"
                                    name="comments"
                                    height={4}
                                    id="comments"
                                    value={comments}
                                    onChange={handleCommentsChange}
                                  />
                                </div>
                              </Row>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        {activeTab !== 2 && (
                        <li
                          className={(activeTab === 5 || (activeTab === 2 && !nextOfKin)) ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={activeTab === 4 ? () => {
                              handleApproveMember()
                            } : () => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            {activeTab === 4 ? 'Submit' : 'Next'}
                          </Link>
                        </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard
