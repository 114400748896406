import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import SplineArea from "./SplineArea";
import DonutChart from "./DonutChart";
import { getMemberStatusCountsApi, getGroupStatusCountsApi, getClaimStatusCountsApi, getApplicationStatusCountsApi, getPaymentsStatusCountsApi } from 'api/report';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | SHOFCO - SUN Portal";
  const { isAuthenticated } = useAuth();
  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isData, address } = useRole();
  const dateFormat = 'DD-MM-YYYY';
  const queryClient = useQueryClient();

  const startDate = moment().startOf('year').format(dateFormat);
  console.log(startDate);
  const endDate = moment().format(dateFormat);
  const addressData = isAuthenticated ? (isFacilitator ? { wardId: address?.wardId } : isSiteCord ? { constituencyId: address?.constituencyId } : isCountyCoordinator ? { countyId: address?.countyId } : isClusterLeader ? { regionId: address?.regionId } : {}) : {}

  const { mutate: mutateMemberCounts,
    isLoading: isLoadingProducts,
    data: memberSummary, } = useMutation(
      (payload) => getMemberStatusCountsApi(payload),
      {
        onSuccess: res => {
          console.log(res)
          // dispatch(setMemberSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateClaimCounts,
    data: claimSummary, } = useMutation(
      (payload) => getClaimStatusCountsApi(payload),
      {
        onSuccess: res => {
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutatePaymentCounts,
    data: paymentsSummary, } = useMutation(
      (payload) => getPaymentsStatusCountsApi(payload),
      {
        onSuccess: res => {
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    mutatePaymentCounts({
      fromDate: startDate,
      toDate: endDate,
      address: {
        ...addressData
      }
    });

    mutateClaimCounts({
      fromDate: startDate,
      toDate: endDate,
      address: {
        ...addressData
      }
    });
    mutateMemberCounts({
      fromDate: startDate,
      toDate: endDate,
      address: {
        ...addressData
      }
    });
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md={8}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                    header={props.t("Admin Dashboard")}
                    title={props.t("Home")}
                    breadcrumbItem={props.t("Dashboards")}
                  />
                </Col>
                <Col md={4}>
                  {
                    !isData && <Row className="align-items-center justify-content-between">
                      <Col>
                        <Link to="/members/create">
                          <Button type="button" size="sm" color="primary" outline className="font-size-10">
                            Add Member
                          </Button>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/payments/create">
                          <Button type="button" size="sm" color="primary" className="font-size-10">
                            Pay Premium
                          </Button>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/claims/create">
                          <Button type="button" size="sm" color="primary" className="font-size-10">
                            Make Claim
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div>
                    <p className="text-bold b-1">Custom Snapshot Reports</p>
                    {/* <p className="text-muted font-size-11 mb-1">
                      Shofco to provide dashboard reports
                    </p> */}
                  </div>
                  {/* <Row className="py-1">
                    <Col >
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <p className="text-muted mb-1">Kes.</p>
                              <h2>{paymentSummary?.totalPremiums || 0} </h2>
                            </div>
                            <p className="text-muted mb-1">Payment To Date</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <p className="text-muted mb-1">Kes.</p>
                              <h2>100 </h2>
                            </div>
                            <p className="text-muted mb-1">Monthly Payment</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="mt-3">
                            <div className="d-flex">
                              <p className="text-muted mb-1">Kes.</p>
                              <h2 className="d-flex font-size-24 align-items-center">
                                3,000{" "}
                                <span className="d-flex text-success font-size-10">
                                  <i className="mdi mdi-arrow-up ms-1"></i> <p >4.5 %</p>
                                </span>
                              </h2>
                            </div>
                            <p className="text-muted mb-1">
                              Monthly Group Target
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> */}
                </Col>
                <Col md={4}></Col>
              </Row>
              {/* <CardTitle className="mb-4"> Spline Area </CardTitle> */}
              <Row className="d-flex justify-content-md-center">
                <Col md={9}>
                  <SplineArea dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' claims={claimSummary?.data || []} premiums={[paymentsSummary?.data || []]} members={memberSummary?.data || []} />
                </Col>
                <Col md={3}>
                  <DonutChart dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' series={memberSummary ? [memberSummary?.data[0].totalFemaleMembers, memberSummary?.data[0].totalMaleMembers] : []} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
