import {
  GET_COVERS_FAIL,
  GET_COVERS_SUCCESS,
  ADD_COVER_SUCCESS,
  ADD_COVER_FAIL,
  UPDATE_COVER_SUCCESS,
  UPDATE_COVER_FAIL,
  DELETE_COVER_SUCCESS,
  DELETE_COVER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  product: {},
  covers: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true
};

const Cover = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COVERS_SUCCESS:
      return {
        ...state,
        covers: action.payload,
        loading: true
      };

    case GET_COVERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_COVER_SUCCESS:
      return {
        ...state,
        covers: [...state.covers, action.payload],
      };

    case ADD_COVER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_COVER_SUCCESS:
      return {
        ...state,
        covers: state.covers.map(cover =>
          (cover.id + '') === (action.payload.id + '')
            ? { cover, ...action.payload }
            : cover
        ),
      };

    case UPDATE_COVER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_COVER_SUCCESS:
      return {
        ...state,
        covers: state.covers.filter(
          cover => cover.id !== action.payload
        ),
      };
    case DELETE_COVER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Cover;
