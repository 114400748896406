import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Input,
  Row, UncontrolledAlert,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link , useParams, useNavigate} from "react-router-dom";
import { useMutation, useQueryClient } from 'react-query';
import logo from "../../assets/images/logo-dark2.png";
import moment from "moment";
import swal from 'sweetalert2';
import { getNOKApplicationByIdApi, approveNOKApplicationApi, rejectNOKApplicationApi } from 'api/member';

const KinEditApplicationDetails = () => {
  const [approvalStatus, setApprovalStatus] = useState("");
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [comments, setComments] = useState("");
  const [modal, setModal] = useState(false);

  const { id } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const toggle = () => setModal(!modal);

  const handleSubmit = async () => {
    try {
      let res;
      if (approvalStatus === "approve") {
        res = await approveNOKApplicationApi({ applicationId: Number(id), comments: comments });
      } else {
        res = await rejectNOKApplicationApi({ applicationId: Number(id), comments: comments });
      }

      swal.fire({
        title: 'Success',
        text: res?.message,
        icon: 'success',
        confirmButtonText: 'OK'
      });

      toggle();
    } catch (err) {
      swal.fire({
        title: 'Error',
        text: "Something is wrong. Please try again later.",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.log(err);
    }
  };


  const { mutate: mutateApplication,
    isLoading: isLoadingApplication,
    data: applicationData } = useMutation(
      (payload) => getNOKApplicationByIdApi(payload),
      {
        onSuccess: (res) => {
          console.log(res);
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
    mutateApplication({
      applicationId: id
    });

  }, [modal])

  //meta title
  document.title = "Application Details | SUN Welfare Administration System";

  if (isLoadingApplication) return <div>Loadding...</div>;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Application" breadcrumbItem="Application Details" />
          <Row>
            <Link to="/kin-applications">
              <Button type="button" color="primary" className="btn">
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </Link>
            <Row>
              <Col>
                {
                  (messageError || messageSuccess) && <UncontrolledAlert color={messageError ? "danger" : 'primary'}>
                    {messageError || messageSuccess}
                  </UncontrolledAlert>
                }
              </Col>
            </Row>
            <Col lg="12">
              <Card style={{ maxWidth: "60%", margin: "0 auto" }}>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Next Of Kin Application Details</h4>
                  </div>
                  <Row className="mb-5">
                    <Col className="text-end">
                      {applicationData?.status === 'New' && (
                        <>
                          <Button type="button" color="primary" className="me-4" onClick={() => {
                            setApprovalStatus('approve');
                            toggle();
                          }}>
                            <i className="bx bx-check"></i> Approve
                          </Button>
                          <Button type="button" color="primary" onClick={() => {
                            setApprovalStatus('reject');
                            toggle();
                          }
                          }>
                            <i className="bx bx-check"></i> Reject
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="mb-4">
                        <img src={logo} alt="Company Logo" style={{ maxWidth: "200px" }} />
                      </div>
                    </Col>
                    <Col md="4">
                      <h2 className={applicationData?.status == 'Approved' ? 'text-primary' : applicationData?.status === 'Rejected' ? 'text-danger' : 'text-warning'}>{applicationData?.status}</h2>
                    </Col>
                    <Col md="4">
                      <h6>Application Date</h6>
                      <p>{moment(applicationData?.insertDate).format("MMM DD, YYYY HH:mm")}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <dl className="row">
                        <dt className="col-sm-4">Application Number:</dt>
                        <dd className="col-sm-8">{applicationData?.applicationId || ''}</dd>

                        <dt className="col-sm-4">Status:</dt>
                        <dd className="col-sm-8">{applicationData?.status}</dd>

                        <dt className="col-sm-4">Application Date:</dt>
                        <dd className="col-sm-8">{moment(applicationData?.insertDate).format("MMM DD, YYYY HH:mm")}</dd>
                      </dl>
                      <h6 className="mt-4">Next Of Kin Information</h6>
                      <dl className="row">
                        <dt className="col-sm-4">Name:</dt>
                        <dd className="col-sm-8">{`${applicationData?.firstName || ''} ${applicationData?.lastName || ''}`}</dd>

                        <dt className="col-sm-4">Relationship:</dt>
                        <dd className="col-sm-8">{applicationData?.relationship || ''}</dd>


                        <dt className="col-sm-4">DOB :</dt>
                        <dd className="col-sm-8">{`${applicationData?.dob || ''}`}</dd>

                        <dt className="col-sm-4">Gender:</dt>
                        <dd className="col-sm-8">{applicationData?.gender || ''}</dd>

                        <dt className="col-sm-4">identification No:</dt>
                        <dd className="col-sm-8">{applicationData?.identificationNumber || ''}</dd>

                      </dl>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{approvalStatus === 'approve' ? 'Approve ' : 'Reject '}Next Of Kin</ModalHeader>
        <ModalBody>
          <Row className="mt-3">
            <div className="text-center">
              <Label for="comments">Comments:</Label>
              <Input
                type="textarea"
                name="comments"
                height={4}
                id="comments"
                value={comments}
                onChange={e => setComments(e.target.value)}
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            SUBMIT
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            CANCEL
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default KinEditApplicationDetails;