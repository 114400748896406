import {
  GET_COVERS,
  GET_COVERS_FAIL,
  GET_COVERS_SUCCESS,
  ADD_NEW_COVER,
  ADD_COVER_SUCCESS,
  ADD_COVER_FAIL,
  UPDATE_COVER,
  UPDATE_COVER_SUCCESS,
  UPDATE_COVER_FAIL,
  DELETE_COVER,
  DELETE_COVER_SUCCESS,
  DELETE_COVER_FAIL
} from "./actionTypes"



export const getCovers = () => ({
  type: GET_COVERS,
})

export const getCoversSuccess = covers => ({
  type: GET_COVERS_SUCCESS,
  payload: covers,
})

export const getCoversFail = error => ({
  type: GET_COVERS_FAIL,
  payload: error,
})

export const addNewCover = cover => ({
  type: ADD_NEW_COVER,
  payload: cover,
})

export const addCoverSuccess = cover => ({
  type: ADD_COVER_SUCCESS,
  payload: cover,
})

export const addCoverFail = error => ({
  type: ADD_COVER_FAIL,
  payload: error,
})

export const updateCover = cover => ({
  type: UPDATE_COVER,
  payload: cover,
})

export const updateCoverSuccess = cover => ({
  type: UPDATE_COVER_SUCCESS,
  payload: cover,
})

export const updateCoverFail = error => ({
  type: UPDATE_COVER_FAIL,
  payload: error,
})

export const deleteCover = cover => ({
  type: DELETE_COVER,
  payload: cover,
})

export const deleteCoverSuccess = cover => ({
  type: DELETE_COVER_SUCCESS,
  payload: cover,
})

export const deleteCoverFail = error => ({
  type: DELETE_COVER_FAIL,
  payload: error,
})
