import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  FormFeedback,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from "reactstrap";
import logo from "../../assets/images/logo-dark2.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getReceiptDetailsByIdApi, createPayment } from 'api/transaction';
import { useRole } from 'hooks/useRole';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CompleteTransaction = () => {
  const { isFacilitator, isSiteCord, address } = useRole();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [receiptData, setReceiptData] = useState({});

  useEffect(() => {

  }, []);

  const calculateTotal = () => {
    if (!receiptData.items || receiptData.items.length === 0) {
      return "0.00";
    }

    const subtotal = receiptData.invoiceDTO?.items.reduce((acc, item) => acc + item.totalAmount, 0);
    const total = subtotal + (subtotal * receiptData.invoiceDTO?.taxAmount) / 100;
    return total.toFixed(2);
  };

  //meta title
  document.title = "Complete Payment | SUN Welfare Administration System";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      transId: '',
      payDate: '',
      billRefNumber: '',
      description: '',
      mobileNumber: '',
      transAmount: '',
      BusinessShortCode: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      billRefNumber: Yup.string().required("Please Enter Bill Ref Number"),
      transId: Yup.string().required("Please Enter Transaction Id"),
      mobileNumber: Yup.string().required("Please Enter Mobile Number"),
      transAmount: Yup.string().required("Please Enter Amount Paid"),
      payDate: Yup.string().required("Please Select Pay date"),
      BusinessShortCode: Yup.string().required("Please Enter BusinessShortCode"),
    }),

    onSubmit: async (values) => {
      let groupData = {
        TransID: values?.transId,
        TransTime: values?.payDate,
        description: values?.description,
        msisdn: values?.mobileNumber,
        transAmount: values?.transAmount,
        BusinessShortCode: values?.BusinessShortCode,
        FirstName: values.firstName,
        MiddleName: values.firstName,
        LastName: values.lastName,
        OrgAccountBalance: 0,
        ThirdPartyTransID: '',
        TransactionType: 'Pay Bill',
        BillRefNumber: values.billRefNumber
      }

      const res = await createPayment(groupData)
      if (res.status === "Success") {
        setShowPopup(true)
        navigate(-1);
      } else {
        // Handle error case if needed
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Receipts" breadcrumbItem="Receipt Details" />
          <Link to="/payments">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
          <Card style={{ maxWidth: "60%", margin: "0 auto" }}>
            <CardBody>
              <Row>
                {/* Logo */}
                <Col md={4}>
                  <img src={logo} alt="Company Logo" style={{ maxWidth: "200px" }} />
                </Col>
                {/* Title and Date */}
                <Col md={12} className="text-center">
                  <h4>COMPLETE PAYMENT TRANSACTION</h4>
                </Col>
              </Row>

              <Form autoComplete="off" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              >
              <Row>
              <Col lg="6">
                    <div className="mb-3">
                      <Label for="firstName">
                        First Name
                      </Label>
                      <Input
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="Enter First Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName && validation.errors.firstName ? true : false
                        }
                      />
                      {validation.touched.firstName && validation.errors.firstName ? (
                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="lastName">
                        Last Name
                      </Label>
                      <Input
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Enter Last Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                      />
                    </div>
                  </Col>
              </Row>
                <Row>
                
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="name">
                        Transaction ID
                      </Label>
                      <Input
                        type="text"
                        name="transId"
                        className="form-control"
                        id="transId"
                        placeholder="Enter Transaction ID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transId || ""}
                        invalid={
                          validation.touched.transId && validation.errors.transId ? true : false
                        }
                      />
                      {validation.touched.transId && validation.errors.transId ? (
                        <FormFeedback type="invalid">{validation.errors.transId}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
    
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="mobileNumber">
                        Mobile Number
                      </Label>
                      <Input
                        type="text"
                        name="mobileNumber"
                        className="form-control"
                        id="mobileNumber"
                        onChange={validation.handleChange}
                        placeholder="Enter Mobile Number"
                        onBlur={validation.handleBlur}
                        value={validation.values.mobileNumber || ""}
                        invalid={
                          validation.touched.mobileNumber && validation.errors.mobileNumber ? true : false
                        }
                      />
                      {validation.touched.mobileNumber && validation.errors.mobileNumber ? (
                        <FormFeedback type="invalid">{validation.errors.mobileNumber}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="transAmount">
                        Trans Amount
                      </Label>
                      <Input
                        type="number"
                        name="transAmount"
                        className="form-control"
                        id="basicpill-phoneno-input3"
                        placeholder="Enter Trans Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transAmount || ""}
                        invalid={
                          validation.touched.transAmount && validation.errors.transAmount ? true : false
                        }
                      />
                      {validation.touched.transAmount && validation.errors.transAmount ? (
                        <FormFeedback type="invalid">{validation.errors.transAmount}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="payDate">
                        Pay Date
                      </Label>
                      <Input
                        type="datetime-local"
                        name="payDate"
                        className="form-control"
                        id="basicpill-phoneno-input3"
                        placeholder="Enter Pay Date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.payDate || ""}
                        invalid={
                          validation.touched.payDate && validation.errors.payDate ? true : false
                        }
                      />
                      {validation.touched.payDate && validation.errors.payDate ? (
                        <FormFeedback type="invalid">{validation.errors.payDate}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                    <div className="mb-3">
                      <Label for="BusinessShortCode">
                       Business Short Code
                      </Label>
                      <Input
                        type="text"
                        name="BusinessShortCode"
                        className="form-control"
                        id="BusinessShortCode"
                        onChange={validation.handleChange}
                        placeholder="Enter BusinessShortCode"
                        onBlur={validation.handleBlur}
                        value={validation.values.BusinessShortCode || ""}
                        invalid={
                          validation.touched.BusinessShortCode && validation.errors.BusinessShortCode ? true : false
                        }
                      />
                      {validation.touched.BusinessShortCode && validation.errors.BusinessShortCode ? (
                        <FormFeedback type="invalid">{validation.errors.BusinessShortCode}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="billRefNumber">
                        Bill Reference Number
                      </Label>
                      <Input
                        type="text"
                        name="billRefNumber"
                        className="form-control"
                        id="basicpill-phoneno-input3"
                        placeholder="Enter Pay Date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billRefNumber || ""}
                        invalid={
                          validation.touched.billRefNumber && validation.errors.billRefNumber ? true : false
                        }
                      />
                      {validation.touched.billRefNumber && validation.errors.billRefNumber ? (
                        <FormFeedback type="invalid">{validation.errors.billRefNumber}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md ={12}>
                    <div className="mb-3">
                      <Label for="description">
                        Description
                      </Label>
                      <Input
                        type="textarea"
                        height={3}
                        className="form-control"
                        name="description"
                        id="basicpill-phoneno-input3"
                        placeholder="Enter Description"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="text-end">
                  <Col md="12">
                    <Button color="primary" block type="submit">Create Group</Button>
                  </Col>
                </Row>
              </Form>
              <Modal isOpen={showPopup} toggle={() => setShowPopup(!showPopup)}>
                <ModalBody>
                  Payment Submitted successfully.
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowPopup(!showPopup)}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompleteTransaction;