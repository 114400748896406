import {
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  ADD_NEW_INVOICE,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAIL,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAIL,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,
} from "./actionTypes";

export const getInvoices = () => ({
  type: GET_INVOICES,
})

export const getInvoicesSuccess = orders => ({
  type: GET_INVOICES_SUCCESS,
  payload: orders,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})

export const addNewInvoice = order => ({
  type: ADD_NEW_INVOICE,
  payload: order,
})

export const addInvoiceSuccess = order => ({
  type: ADD_INVOICE_SUCCESS,
  payload: order,
})

export const addInvoiceFail = error => ({
  type: ADD_INVOICE_FAIL,
  payload: error,
})

export const updateInvoice = order => ({
  type: UPDATE_INVOICE,
  payload: order,
})

export const updateInvoiceSuccess = order => ({
  type: UPDATE_INVOICE_SUCCESS,
  payload: order,
})

export const updateInvoiceFail = error => ({
  type: UPDATE_INVOICE_FAIL,
  payload: error,
})

export const deleteInvoice = order => ({
  type: DELETE_INVOICE,
  payload: order,
})

export const deleteInvoiceSuccess = order => ({
  type: DELETE_INVOICE_SUCCESS,
  payload: order,
})

export const deleteInvoiceFail = error => ({
  type: DELETE_INVOICE_FAIL,
  payload: error,
})
