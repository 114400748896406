import {
  GET_BENEFITS,
  GET_BENEFITS_FAIL,
  GET_BENEFITS_SUCCESS,
  ADD_NEW_BENEFIT,
  ADD_BENEFIT_SUCCESS,
  ADD_BENEFIT_FAIL,
  UPDATE_BENEFIT,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  DELETE_BENEFIT,
  DELETE_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAIL
} from "./actionTypes"



export const getBenefits = () => ({
  type: GET_BENEFITS,
})

export const getBenefitsSuccess = benefits => ({
  type: GET_BENEFITS_SUCCESS,
  payload: benefits,
})

export const getBenefitsFail = error => ({
  type: GET_BENEFITS_FAIL,
  payload: error,
})

export const addNewBenefit = benefit => ({
  type: ADD_NEW_BENEFIT,
  payload: benefit,
})

export const addBenefitSuccess = benefit => ({
  type: ADD_BENEFIT_SUCCESS,
  payload: benefit,
})

export const addBenefitFail = error => ({
  type: ADD_BENEFIT_FAIL,
  payload: error,
})

export const updateBenefit = benefit => ({
  type: UPDATE_BENEFIT,
  payload: benefit,
})

export const updateBenefitSuccess = benefit => ({
  type: UPDATE_BENEFIT_SUCCESS,
  payload: benefit,
})

export const updateBenefitFail = error => ({
  type: UPDATE_BENEFIT_FAIL,
  payload: error,
})

export const deleteBenefit = benefit => ({
  type: DELETE_BENEFIT,
  payload: benefit,
})

export const deleteBenefitSuccess = benefit => ({
  type: DELETE_BENEFIT_SUCCESS,
  payload: benefit,
})

export const deleteBenefitFail = error => ({
  type: DELETE_BENEFIT_FAIL,
  payload: error,
})
