import { ROLES } from 'configs/constants';

export const useRole = () => {
    const role = localStorage.getItem('shofco_role');
    const address = JSON.parse(localStorage.getItem('address'));
    return {
        isFacilitator: role === ROLES.Facilitator,
        isSiteCord: role === ROLES.SiteCoordinator,
        isFinance: role === ROLES.FinanceOfficer,
        isAdmin: role === ROLES.Admin,
        isSuperAdmin: role === ROLES.SuperAdmin,
        isCountyCoordinator: role === ROLES.CountyCoordinator,
        isClusterLeader: role === ROLES.ClusterLeader,
        isDirector: role === ROLES.Director,
        isData: role === ROLES.Data,
        address,
    };
}