

/* APPLICATIONS */
export const SET_APPLICATIONS_SUMMARY = "SET_APPLICATIONS_SUMMARY"
export const SET_CLAIM_SUMMARY  = "SET_CLAIM_SUMMARY"
export const SET_GROUP_SUMMARY  = "SET_GROUP_SUMMARY"
export const SET_MEMBERS_SUMMARY  = "SET_MEMBERS_SUMMARY"
export const SET_PAYMENTS_SUMMARY = "SET_PAYMENTS_SUMMARY";
export const SET_TRANSFER_SUMMARY = "SET_TRANSFER_SUMMARY";


