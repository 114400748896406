import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getRiskClasss as onGetRiskClasss,
  addNewRiskClass as onAddNewRiskClass,
  updateRiskClass as onUpdateRiskClass,
  deleteRiskClass as onDeleteRiskClass,
} from "store/actions";

import {
  RiskClassId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./RiskClassCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import RiskClassModal from "./RiskClassModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { useRole } from 'hooks/useRole';
import { useMutation, useQueryClient } from 'react-query';
import { fetchRiskClassApi } from 'api/setup';

function RiskClass() {
  const { t, i18n } = useTranslation();
  const { isData, isAdmin } = useRole();
  const [pages, setPages] = useState(0);

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: []
  });
  const riskclassCardData = [
    { title: "Total RiskClasss", iconClass: "bx bx-group", description: "21,318" },
    { title: "Active", iconClass: "bx bx-trophy", description: "42,178" },
    {
      title: "Inactive",
      iconClass: "bx bx-dislike",
      description: "516,899",
    },
  ];

  //meta title
  document.title = "RiskClasss | SUN Welfare Administration System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setRiskClass] = useState(null);

  const queryClient = useQueryClient();

  const { mutate: mutateRiskClass,
    isLoading: isLoadingRiskClass,
    data: riskClassData, } = useMutation(
      (payload) => fetchRiskClassApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          setLoading(false);
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid RiskClass Id"
        ).required("Please Enter Your RiskClass Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your RiskClass Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateRiskClass = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateRiskClass(updateRiskClass));
        validation.resetForm();
      } else {
        const newRiskClass = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewRiskClass(newRiskClass));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.riskClass;
  const EcommerceRiskClassProperties = createSelector(
    selectEcommerceState,
    (riskClass) => ({
      groups: [],
      // TO-DO MAKE SURE TO => return this for live data
      // groups: Ecommerce.groups,
      loading: riskClass.loading
    })
  );

  const { groups, loading } = useSelector(EcommerceRiskClassProperties);

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    mutateRiskClass();
  }, [pagination]);

  const onChangePagination = (
    { page, limit }, filters, sorter) => {

    console.log(filters)
    let searchColumns = isEmpty(filters) ? [] : [
      filters?.id && {
        id: filters?.id
      },
      filters?.applicationNumber && {
        applicationNumber: filters?.applicationNumber
      }
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setRiskClass(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16">
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        ),
      },
      {
        Header: 'PRODUCT ID',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: 'center',
          width: '10%',
          background: '#0000',
        },
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'PRODUCT NAME',
        accessor: 'name',
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <RiskClassModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="RiskClasss" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        columns={columns}
                        data={riskClassData || []}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        // hasCreate
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        // showDelete
                        showEdit={isAdmin}
                        showView
                        pages={pages}
                        resource="riskClass"
                        addText="Add New RiskClass"
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        paginationData={pagination}
                        onChangePagination={onChangePagination}
                        searchByNames={[
                          {
                            value: 'email',
                            text: t('common.email'),
                          },
                          {
                            value: 'idNumber',
                            text: t('common.idNumber'),
                          },
                          {
                            value: 'applicationNumber',
                            text: t('applications.applicationNumber'),
                          }
                        ]}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit RiskClass" : "Add RiskClass"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>RiskClass Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert RiskClass Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ""}
                        invalid={
                          validation.touched.orderId && validation.errors.orderId ? true : false
                        }
                      />
                      {validation.touched.orderId && validation.errors.orderId ? (
                        <FormFeedback type="invalid">{validation.errors.orderId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>RiskClass Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("orderdate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate && validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.orderdate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
RiskClass.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default RiskClass;
