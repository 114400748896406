import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes";

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = orders => ({
  type: GET_USERS_SUCCESS,
  payload: orders,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const addNewUser = order => ({
  type: ADD_NEW_USER,
  payload: order,
})

export const addUserSuccess = order => ({
  type: ADD_USER_SUCCESS,
  payload: order,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = order => ({
  type: UPDATE_USER,
  payload: order,
})

export const updateUserSuccess = order => ({
  type: UPDATE_USER_SUCCESS,
  payload: order,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = order => ({
  type: DELETE_USER,
  payload: order,
})

export const deleteUserSuccess = order => ({
  type: DELETE_USER_SUCCESS,
  payload: order,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
