import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard";

//Pages
import Applications from "../pages/Applications";
import Audits from "../pages/Audits";

import ApplicationDetails from "../pages/Applications/ApplicationDetails";
import AddApplication from "../pages/Applications/AddApplication";
import Members from "../pages/Members";
import MemberDetails from "../pages/Members/MemberDetails";
import AddMember from "../pages/Members/AddMember";
import NewDependents from "../pages/Members/NewDependents";
import NewDependentDetails from "../pages/Members/NewDependentDetails";
import Groups from "../pages/Groups";
import GroupDetails from "../pages/Groups/GroupDetails";
import AddGroup from "../pages/Groups/AddGroup";
import GroupTransfers from "../pages/GroupTransfers";
import MemberTransfer from "../pages/MemberTransfer";
import AddMemberTransfer from "../pages/MemberTransfer/AddMemberTransfer";
import GroupTransferDetails from "../pages/GroupTransfers/GroupTransferDetails";
import MemberTransferDetails from "../pages/MemberTransfer/MemberTransferDetails";
import AddGroupTransfer from "../pages/GroupTransfers/AddGroupTransfer";
import Claims from "../pages/Claim";
import ClaimDetails from "../pages/Claim/ClaimDetails";
import AddClaim from "../pages/Claim/AddClaim";
import Users from "../pages/Users";
import UserDetails from "../pages/Users/UserDetails";
import AddUser from "../pages/Users/AddUser";
import Roles from "../pages/Roles";
import AddRole from "../pages/Roles/AddRole";
import RoleDetails from "../pages/Roles/RoleDetails";
import Transactions from "../pages/Payments";
import TransactionDetails from "../pages/Payments/TransactionDetails";
import CompleteTransaction from "../pages/Payments/CompleteTransaction";
import AddTransaction from "../pages/Payments/AddPayment";
import Invoices from "../pages/Invoices";
import InvoiceDetails from "../pages/Invoices/InvoiceDetails";
import AddInvoice from "../pages/Invoices/AddInvoice";
import Receipts from "../pages/Receipts";
import ReceiptsDetails from "../pages/Receipts/ReceiptDetails";
import AddReceipts from "../pages/Receipts/AddReceipt";
import Communications from "../pages/Communications";
import CommunicationDetails from "../pages/Communications/CommunicationDetails";
import AddCommunication from "../pages/Communications/AddCommunication";
import Covers from "../pages/Covers";
import CoverDetails from "../pages/Covers/CoverDetails";
import AddCover from "../pages/Covers/AddCover";
import Products from "../pages/Products/";
import ProductDetails from "../pages/Products/ProductDetails";
import AddProduct from "../pages/Products/AddProduct";
import BenefitTypes from "../pages/BenefitTypes";
import BenefitTypeDetails from "../pages/BenefitTypes/BenefitTypeDetails";
import AddBenefitType from "../pages/BenefitTypes/AddBenefitType";
import Benefits from "../pages/Benefits/";
import BenefitDetails from "../pages/Benefits/BenefitDetails";
import AddBenefit from "../pages/Benefits/AddBenefit";
import RiskClass from "../pages/RiskClass";
import RiskClassDetails from "../pages/RiskClass/RiskClassDetails";
import AddRiskClass from "../pages/RiskClass/AddRiskClass";
import Categories from "../pages/Categories";
import AddCategory from "../pages/Categories/AddCategory";
import CategoryDetails from "../pages/Categories/CategoryDetails";
import MemberReports from "../pages/Reports/MemberReports";
import PaymentReports from "../pages/Reports/PaymentReports";
import GroupReports from "../pages/Reports/GroupReports";
import ClaimReports from "../pages/Reports/ClaimReports";
import Map from "pages/Reports/Map"
import NextOfKinEditApplications from "pages/Members/NextOfKinEditApplications";
import NextOfKinEditApplicationDetails from "pages/Members/KinEditApplicationDetails";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/new-dependents", component: <NewDependents /> },
  { path: "/new-dependents/:id", component: <NewDependentDetails /> },

  { path: "/kin-applications", component: <NextOfKinEditApplications /> },
  { path: "/kin-applications/:id", component: <NextOfKinEditApplicationDetails /> },

  //pages
  { path: "/applications", component: <Applications /> },
  { path: "/applications/:id", component: <ApplicationDetails /> },
  { path: "/applications/:id/edit", component: <AddApplication /> },
  { path: "/applications/create", component: <AddApplication /> },

  { path: "/members/:id", component: <MemberDetails /> },
  { path: "/members/:id/edit", component: <AddMember /> },
  { path: "/members", component: <Members /> },
  { path: "/members/create", component: <AddMember /> },

  { path: "/groups/:id", component: <GroupDetails /> },
  { path: "/groups/:id/edit", component: <AddGroup /> },
  { path: "/groups", component: <Groups /> },
  { path: "/groups/create", component: <AddGroup /> },

  { path: "/groupTransfers/:id", component: <GroupTransferDetails /> },
  { path: "/groupTransfers/:id/edit", component: <AddGroupTransfer /> },
  { path: "/groupTransfers", component: <GroupTransfers /> },
  { path: "/groupTransfers/create", component: <AddGroupTransfer /> },

  { path: "/member-transfers/:id", component: <MemberTransferDetails /> },
  { path: "/member-transfers/:id/edit", component: <AddMemberTransfer /> },
  { path: "/member-transfers", component: <MemberTransfer /> },
  { path: "/member-transfers/create", component: <AddMemberTransfer /> },

  { path: "/claims/:id", component: <ClaimDetails /> },
  { path: "/claims/:id/edit", component: <AddClaim /> },
  { path: "/claims", component: <Claims /> },
  { path: "/claims/create", component: <AddClaim /> },

  { path: "/users/:id", component: <UserDetails /> },
  { path: "/users/:id/edit", component: <AddUser /> },
  { path: "/users", component: <Users /> },
  { path: "/users/create", component: <AddUser /> },

  { path: "/roles/:id", component: <RoleDetails /> },
  { path: "/roles/:id/edit", component: <AddRole /> },
  { path: "/roles", component: <Roles /> },
  { path: "/roles/create", component: <AddRole /> },

  { path: "/payments/:id", component: <TransactionDetails /> },
  { path: "/payments/:id/edit", component: <AddTransaction /> },
  { path: "/payments", component: <Transactions /> },
  { path: "/payments/create", component: <AddTransaction /> },
  { path: "/payments/complete-transaction", component: <CompleteTransaction /> },

  { path: "/invoices/:id", component: <InvoiceDetails /> },
  { path: "/invoices/:id/edit", component: <AddInvoice /> },
  { path: "/invoices", component: <Invoices /> },
  { path: "/invoices/create", component: <AddInvoice /> },

  { path: "/receipts/:id", component: <ReceiptsDetails /> },
  { path: "/receipts/:id/edit", component: <AddReceipts /> },
  { path: "/receipts", component: <Receipts /> },
  { path: "/receipts/create", component: <AddReceipts /> },

  { path: "/products/:id", component: <ProductDetails /> },
  { path: "/products/:id/edit", component: <AddProduct /> },
  { path: "/products", component: <Products /> },
  { path: "/products/create", component: <AddProduct /> },

  { path: "/cover/:id", component: <CoverDetails /> },
  { path: "/cover/:id/edit", component: <AddCover /> },
  { path: "/cover", component: <Covers /> },
  { path: "/cover/create", component: <AddCover /> },

  { path: "/benefit-types/:id", component: <BenefitTypeDetails /> },
  { path: "/benefit-types/:id/edit", component: <AddBenefitType /> },
  { path: "/benefit-types", component: <BenefitTypes /> },
  { path: "/benefit-types/create", component: <AddBenefitType /> },

  { path: "/benefits/:id", component: <BenefitDetails /> },
  { path: "/benefits/:id/edit", component: <AddBenefit /> },
  { path: "/benefits", component: <Benefits /> },
  { path: "/benefits/create", component: <AddBenefit /> },

  { path: "/audit-logs", component: <Audits /> },

  { path: "/risk-class/:id", component: <RiskClassDetails /> },
  { path: "/risk-class/:id/edit", component: <AddRiskClass /> },
  { path: "/risk-class", component: <RiskClass /> },
  { path: "/risk-class/create", component: <AddRiskClass /> },

  { path: "/categories/:id", component: <CategoryDetails /> },
  { path: "/categories/:id/edit", component: <AddCategory /> },
  { path: "/categories", component: <Categories /> },
  { path: "/categories/create", component: <AddCategory /> },

  { path: "/communications/:id", component: <CommunicationDetails /> },
  { path: "/communications/:id/edit", component: <AddCommunication /> },
  { path: "/communications", component: <Communications /> },
  { path: "/communications/create", component: <AddCommunication /> },

  { path: "/reports/member-reports", component: <MemberReports /> },
  { path: "/reports/group-reports", component: <GroupReports /> },
  { path: "/reports/claim-reports", component: <ClaimReports /> },
  { path: "/reports/payment-reports", component: <PaymentReports /> },
  { path: "/reports/map", component: <Map /> },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
