import React, { useEffect, useState } from 'react';
import {
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabPane,
    Button,
    FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import moment from 'moment'
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from "react-redux";
import { getAllRelationshipsApi } from 'api/report';
import { addDependant, uploadDepeMedia } from 'api/member';
import { editMember } from "store/actions";
import omit from 'lodash/omit';

const AddDependants = ({ onChange, member, onNext }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [file, setSelectedFile] = useState(null);

    const { mutate: mutateRelationships,
        isLoading: isLoadingRelationships,
        data: relationshipsData, } = useMutation(
            (payload) => getAllRelationshipsApi(payload),
            {
                onSettled: () => {
                    queryClient.invalidateQueries('/public/um/setup/relationship');
                },
            }
        );

    // Define validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string().required("Please Enter First Name"),
        lastName: Yup.string().required("Please Enter Last Name"),
        relationship: Yup.string().required("Please Select Relationship"),
        gender: Yup.string().required("Please Select Gender"),
        dob: Yup.string().required("Please Select DOB"),
        // certificateofbirth: Yup.string().required("Please Select Birth Certificate file"),
    });

    // Initialize formik
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            relationship: '',
            mobileNumber: '',
            gender: '',
            dob: '',
            certificateofbirth: ''
        },
        validationSchema,
        onSubmit: async ({ dob, ...values }) => {
            let depId = null;
            const data = {
                ...values,
                memberId: member?.memberId,
                dob: moment(dob).format("DD-MM-YYYY"),
            }
            await addDependant(data).then(res => {
                if (res.status === 'Success') {
                    depId = res?.data?.id;
                    validation.resetForm();
                }
                else {
                    // TODO SHOW ERROR
                }

            });
            depId && uploadDepeMedia(depId, file);
            // onNext();
        },
    });

    const addNewDependant = () => {
        const newDependant = {
            title: `Dependant ${appMember.dependants.length + 1}`,
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            dob: new Date().getDate(),
            mobileNumber: "",
            relationship: "",
        };
        dispatch(editMember([...appMember.dependants, newDependant], 'dependant'));
    };

    const removeDependant = (index) => {
        const updatedDependants = [...appMember.dependants];
        updatedDependants.splice(index, 1);
        dispatch(editMember(updatedDependants, 'dependant'));
    };

    const handleDependantChange = (index, key, value) => {
        const updatedDependants = [...appMember.dependants];
        updatedDependants[index][key] = value;
        dispatch(editMember(updatedDependants, 'dependant'))
    };

    const handleAddDependant = async () => {
        try {
            const { dependants } = appMember;
            let actions = [];
            dependants && dependants.map(d => {
                omit(d, ['title'])
                d['memberId'] = member.memberId;
                d['dob'] = moment(d.dob).format('MM-DD-YYYY');
                actions.push(addDependant(d));
            });
            Promise.all(actions).then(() => {
                onNext();
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        mutateRelationships({
            type: 'Nuclear'
        });
    }, []);

    const handleFileChange = e => {
        setSelectedFile(e.currentTarget.files[0]);
        validation.handleChange(e);
    };

    return (
        <div>
            <Form autoComplete="off" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>

                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`firstName`}>First Name</Label>
                            <Input
                                type="text"
                                name={`firstName`}
                                className="form-control"
                                id={`firstName`}
                                placeholder="Enter First Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                    validation.touched.firstName && validation.errors.firstName ? true : false
                                }
                            />
                            {validation.touched.firstName && validation.errors.firstName ? (
                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`lastName`}>Last Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                name={`lastName`}
                                id={`lastName`}
                                placeholder="Enter Last Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                    validation.touched.lastName && validation.errors.lastName ? true : false
                                }
                            />
                            {validation.touched.lastName && validation.errors.lastName ? (
                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`gender`}>Sex</Label>
                            <Input
                                className="form-select"
                                id={`gender`}
                                name='gender'
                                type='select'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gender || ""}
                                invalid={
                                    validation.touched.gender && validation.errors.gender ? true : false
                                }
                            >
                                <option defaultValue>Select Gender...</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Intersex">Intersex</option>
                                <option value="Other">Other</option>
                            </Input>
                            {validation.touched.gender && validation.errors.gender ? (
                                <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`dob`}>
                                Date Of birth
                            </Label>
                            <Input
                                name={`dob`}
                                type="date"
                                className="form-control"
                                id={`dob`}
                                placeholder="Select Date of Birth"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dob || ""}
                                // min={new Date(
                                //     new Date().setFullYear(new Date().getFullYear() - 20)
                                //   ).toISOString().split("T")[0]}
                                // invalid={
                                //     validation.touched.dob && validation.errors.dob ? true : false
                                // }
                            />
                            {validation.touched.dob && validation.errors.dob ? (
                                <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`mobileNumber`}>Phone Number</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id={`mobileNumber`}
                                placeholder="Enter Phone Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobileNumber || ""}
                            />

                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label>Relationship</Label>
                            <Input className="form-select"
                                name="relationship"
                                type='select'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.relationship || ""}
                                invalid={
                                    validation.touched.relationship && validation.errors.relationship ? true : false
                                }
                            >
                                <option defaultValue value={''}>
                                    Select Relationship...
                                </option>
                                {
                                    relationshipsData && relationshipsData.map(item => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </Input>
                            {validation.touched.relationship && validation.errors.relationship ? (
                                <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for={`email`}>
                                Enter Email Address
                            </Label>
                            <Input
                                name={`email`}
                                type="text"
                                className="form-control"
                                id={`email`}
                                placeholder="Enter Email Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                            />

                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            {/* Conditionally render label based on the selected relationship */}
                            <Label for="certificateofbirth">
                                {validation.values.relationship === "Husband" || validation.values.relationship === "Wife" ? "Upload Dependent Identification Card" : "Upload Dependent Birth Certificate"}
                            </Label>
                            <Input
                                type="file"
                                name="certificateofbirth"
                                className="form-control"
                                id="certificateofbirth"
                                value={validation.values.certificateofbirth || ""}
                                placeholder={validation.values.relationship === "Husband" || validation.values.relationship === "Wife" ? "Upload Dependent Identification Card" : "Upload Dependent Birth Certificate"}
                                onChange={handleFileChange}
                                // invalid={validation.touched.certificateofbirth && validation.errors.certificateofbirth ? true : false}
                            />
                            {/* {validation.touched.certificateofbirth && validation.errors.certificateofbirth ? (
                                <FormFeedback type="invalid">{validation.errors.certificateofbirth}</FormFeedback>
                            ) : null} */}
                        </div>
                    </Col>
                </Row>
                {/* <p
                        style={{
                            color: "red",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginTop: "5px",
                        }}
                        onClick={() => removeDependant(index)}
                    >
                        Remove Dependant
                    </p> */}
                {/* </div>
                    ))} */}
                <Row className="mt-4">
                    {/* <Col md="6">
                    <FormGroup>
                        <Button type="button" color="info" onClick={addNewDependant}>
                            Other Dependant
                        </Button>
                    </FormGroup>
                </Col> */}
                    <Col md="6">
                        <FormGroup>
                            <Button
                                type="submit"
                                color="primary"
                                block
                            >
                                SAVE AND ADD NEW
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddDependants;
