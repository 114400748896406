import { get, post, put } from './utils';

export async function getAllUsersApi(data) {
    return post(`/api/um/admin/search`, data);
}
export async function getMemberByIdApi(data) {
    return get(`/api/public/um/member/all/1`);
}

export async function getMemberByIdNumberApi(data) {
    return get(`/api/um/member/identification?idNumber=${data}`);
}

export async function addMember(data) {
    return post(`/api/um/admin/application/register`, data);
}

export async function addAppUser(data) {
    return post(`/api/um/admin/addAppUser`, data);
}

export async function updateAppUser(data) {
    return put(`/api/um/admin/`, data);
}

export async function getMemberRolesApi(data) {
    return get(`/api/um/role/sub?roleId=2`);
}

export async function getAppUserByIdApi(id) {
    return get(`/api/um/admin/${id}`);
}

