import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
    addNewMember as onAddNewMember,
    updateMember as onUpdateMember,
    deleteMember as onDeleteMember,
} from "store/actions";

import {
    MemberId,
    BillingName,
    Date,
    Total,
    DepStatus,
    PaymentMethod
} from "./MemberCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import MembersModal from "./MemberModal";
import { useMutation, useQueryClient } from 'react-query';

import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllNewDependentsApi } from 'api/member';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import { sumArray } from 'utils/tools';
import { getDependentsStatusCountsApi } from 'api/report';
import { setMemberSummary } from 'store/actions';
import MainFilter from "components/Common/MainFilter";

function NewDependents() {
    const { isAuthenticated } = useAuth();
    const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, isFinance, isDirector, isAdmin, isSuperAdmin, address } = useRole();

    const { wardId, constituencyId, countyId, regionId } = isAuthenticated ? address : {};
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [addressData, setAddressData] = useState(isFacilitator ? { wardId } : isSiteCord ? { constituencyId } : isCountyCoordinator ? { countyId } : isClusterLeader ? { regionId } : {})
    const [pages, setPages] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const queryClient = useQueryClient();
    const {
        memberSummary,
    } = useSelector((state) => ({
        memberSummary: state.reports.members,
    }));
    const dateFormat = 'DD-MM-YYYY';
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageNumber: 1,
        sortBy: 'id',
        ascendingSort: false,
        searchColumns: [
            ...(isFacilitator ? [
                {
                    "name": "wardId",
                    "value": address?.wardId
                }] : []),
            ...(isCountyCoordinator ? [
                {
                    "name": "countyId",
                    "value": address?.countyId
                }] : []),
            ...(isClusterLeader ? [
                {
                    "name": "regionId",
                    "value": address?.regionId
                }] : []),
            ...(isSiteCord ? [
                {
                    "name": "constituencyId",
                    "value": address?.constituencyId
                }] : []),
            {
                "name": "endDate",
                "value": moment().format(dateFormat)
            },
            {
                "name": "startDate",
                "value": moment().startOf('month').format(dateFormat)
            }
        ]
    });

    const { mutate: mutateMemberCounts,
        isLoading: isLoadingProducts,
        data } = useMutation(
            (payload) => getDependentsStatusCountsApi(payload),
            {
                onSuccess: res => {
                    // dispatch(setMemberSummary(res?.data));
                },
                onSettled: () => {
                    queryClient.invalidateQueries('get-all-counties');
                },
            }
        );

    const { mutate: mutateMembers,
        isLoading: isLoadingMembers,
        data: membersData, } = useMutation(
            (payload) => getAllNewDependentsApi(payload),
            {
                onSuccess: res => {
                    res.pages > 0 && setPages(res.pages);
                },
                onSettled: () => {
                    setLoading(false);
                    queryClient.invalidateQueries('/um/dependent/search');
                },
            }
        );

    const membersCardData = [
        { title: "Total Dependents", iconClass: "bx bx-user", description: memberSummary.length ? sumArray(memberSummary, 'totalDependents').toLocaleString() : 0 },
        { title: "New Dependents", iconClass: "bx bx-pause-circle", description: memberSummary.length ? sumArray(memberSummary, 'newDependents').toLocaleString() : 0 },
        { title: "Approved", iconClass: "bx bx-group", description: memberSummary.length ? sumArray(memberSummary, 'approvedDependents').toLocaleString() : 0 },
        { title: "Rejected", iconClass: "bx bx-check-circle", description: memberSummary.length ? sumArray(memberSummary, 'rejectedDependents').toLocaleString() : 0 },
    ];

    //meta title
    document.title = "Dependents Application | SUN Welfare Administration System";

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);

    const [member, setMember] = useState(null);

    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: (member && member.firstName) || '',
            middleName: (member && member.middleName) || '',
            lastName: (member && member.lastName) || '',
            memberId: (member && member.memberId) || '',
            billingName: (member && member.billingName) || '',
            memberdate: (member && member.memberdate) || '',
            total: (member && member.total) || '',
            paymentStatus: (member && member.paymentStatus) || 'Paid',
            badgeclass: (member && member.badgeclass) || 'success',
            paymentMethod: (member && member.paymentMethod) || 'Mastercard',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please enter First Name"),
            middleName: Yup.string().required("Please enter Middle Name"),
            lastName: Yup.string().required("Please enter Last Name"),
            memberId: Yup.string()
                .matches(
                    /[0-9\.\-\s+\/()]+/,
                    "Please Enter Valid Member Id"
                ).required("Please Enter Your Member Id"),
            billingName: Yup.string().required("Please Enter Your Billing Name"),
            memberdate: Yup.string().required("Please Enter Your Member Date"),
            total: Yup.string().matches(
                /[0-9\.\-\s+\/()]+/,
                "Please Enter Valid Amount"
            ).required("Total Amount"),
            paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
            badgeclass: Yup.string().required("Please Enter Your Badge Class"),
            paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateMember = {
                    id: member ? member.id : 0,
                    firstName: values.firstName,
                    memberId: values.memberId,
                    billingName: values.billingName,
                    memberdate: values.memberdate,
                    total: values.total,
                    paymentStatus: values.paymentStatus,
                    paymentMethod: values.paymentMethod,
                    badgeclass: values.badgeclass,
                };
                // update member
                dispatch(onUpdateMember(updateMember));
                validation.resetForm();
            } else {
                const newMember = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    firstName: values.firstName,
                    memberId: values["memberId"],
                    billingName: values["billingName"],
                    memberdate: values["memberdate"],
                    total: values["total"],
                    paymentStatus: values["paymentStatus"],
                    paymentMethod: values["paymentMethod"],
                    badgeclass: values["badgeclass"],
                };
                // save new member
                dispatch(onAddNewMember(newMember));
                validation.resetForm();
            }
            toggle();
        },
    });

    const toggleViewModal = () => setModal1(!modal1);

    useEffect(() => {
        mutateMembers({ ...pagination, pageNumber: pagination.pageNumber - 1 });
    }, [pagination]);

    useEffect(() => {
        mutateMemberCounts({
            fromDate: moment().startOf('month').format('DD-MM-YYYY'),
            toDate: moment().format('DD-MM-YYYY'),
            address: {
                ...addressData
            }
        });
    }, [addressData]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setMember(null);
        } else {
            setModal(true);
        }
    };

    //delete member
    const [deleteModal, setDeleteModal] = useState(false);

    const handleDeleteMember = () => {
        if (member && member.id) {
            dispatch(onDeleteMember(member.id));
            setDeleteModal(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "#",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return <input type="checkbox" className="form-check-input" />;
                },
            },
            {
                Header: 'FULL NAMES',
                accessor: row => `${row.firstName || ''} ${row.lastName || ''}`,
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: cellProps => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: "Relationship",
                accessor: "relationship",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <BillingName {...cellProps} />;
                },
            },
            {
                Header: "Gender",
                accessor: "gender",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Age(Yrs)",
                accessor: "dob",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const dateValue = moment(cellProps.value, 'DD-MM-YYYY');
                    const formattedDate = cellProps.value ? moment().diff(dateValue, 'year') : 'No DOB Set';
                    return <span>{formattedDate}</span>;
                },
            },
            {
                Header: "Birth Certificate No",
                accessor: "birthCertificationNumber",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <Total {...cellProps} />;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                hidden: false,
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <DepStatus {...cellProps} />;
                },
            },
            {
                Header: "Phone Number",
                accessor: "mobileNumber",
                disableFilters: true,
                Cell: cellProps => {
                    return <PaymentMethod {...cellProps} />;
                },
            },
        ],
        []
    );

    const onChangePagination = (
        { page, limit }, filters, sorter) => {
        let searchColumns = isEmpty(filters) ? pagination.searchColumns : [
            ...pagination.searchColumns,
            ...(filters?.id ? [
                {
                    "name": "id",
                    "value": filters?.id
                }] : []),
            ...(filters?.firstName ? [
                {
                    "name": "firstName",
                    "value": filters?.firstName
                }] : []),
            ...(filters?.lastName ? [
                {
                    "name": "lastName",
                    "value": filters?.lastName
                }] : []),
            ...(filters?.email ? [
                {
                    "name": "email",
                    "value": filters?.email
                }] : []),
            ...(filters?.memberId ? [
                {
                    "name": "memberId",
                    "value": filters?.memberId
                }] : []),
            ...(filters?.shofcoId ? [
                {
                    "name": "shofcoId",
                    "value": filters?.shofcoId
                }] : []),
            ...(filters?.idNumber ? [
                {
                    "name": "idNumber",
                    "value": filters?.idNumber
                }] : []),
            ...(filters?.mobileNumber1 ? [
                {
                    "name": "mobileNumber1",
                    "value": filters?.mobileNumber1
                }] : []),
        ];
        setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
    }

    const onEdit = row => {
        navigate(`${location.pathname}/${row.applicationId}/edit`);
    }

    const handleViewMember = (record) => {
        navigate(`${location.pathname}/${record.applicationId}`);
    };

    const onSearch = (data) => {
        const searchColumns = [];
        if (!isEmpty(data)) {
            if (isFacilitator) {
                searchColumns.push({ name: "wardId", value: address?.wardId });
            } else if (isSiteCord && data?.wardId) {
                searchColumns.push(data?.wardId);
            } else if (isCountyCoordinator && data?.wardId) {
                searchColumns.push(data?.countyId);
            } else {
                if ((isClusterLeader || isFinance || isDirector || isAdmin || isSuperAdmin) && data?.regionId) {
                    searchColumns.push(data?.regionId);
                }
                if (data.wardId) {
                    searchColumns.push(data?.wardId);
                }
                if (data.constituencyId) {
                    searchColumns.push(data?.constituencyId);
                }
                if (data.countyId) {
                    searchColumns.push(data?.countyId);
                }

            }
            data.startDate && searchColumns.push(data.startDate);
            data.endDate && searchColumns.push(data.endDate);
            let add = {}
            searchColumns.forEach((a, i) => {
                add[a.name] = a.value;
            });
            setAddressData(add);
        }

        setPagination({ ...pagination, searchColumns });
    }

    return (
        <React.Fragment>
            <MembersModal isOpen={modal1} toggle={toggleViewModal} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteMember}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Dependents Applications" />
                    <Row>
                        {/* Reports Render */}
                        {membersCardData.map((report, key) => (
                            <Col md="" key={"_col_" + key}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-small" style={{ fontSize: 10 + 'px' }}>
                                                    {report.title}
                                                </p>
                                                <h4 className="mb-0">{report.description}</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i
                                                        className={
                                                            "bx " + report.iconClass + " font-size-24"
                                                        }
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <MainFilter onSearch={onSearch} showDateFilter />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={membersData?.list?.reverse() || []}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        isPagination={true}
                                        filterable={false}
                                        hasSearch
                                        // showEdit={isAuthenticated && (isFacilitator || isSuperAdmin || isAdmin)}
                                        showView
                                        loading={isLoadingMembers}
                                        pages={pages}
                                        handleEdit={onEdit}
                                        handleView={handleViewMember}
                                        iscustomPageSizeOptions={true}
                                        addText="Add New Member"
                                        tableClass="align-middle table-nowrap table-check"
                                        theadClass="table-light"
                                        pagination="pagination pagination-rounded justify-content-end mb-2"
                                        paginationData={pagination}
                                        onChangePagination={onChangePagination}
                                        searchByNames={[
                                            {
                                                value: 'email',
                                                text: t('common.email'),
                                            },
                                            {
                                                value: 'idNumber',
                                                text: t('common.idNumber'),
                                            },
                                            {
                                                value: 'firstName',
                                                text: t('users.firstName'),
                                            },
                                            {
                                                value: 'lastName',
                                                text: t('users.lastName'),
                                            },
                                            {
                                                value: 'mobileNumber1',
                                                text: t('common.mobileNumber'),
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
NewDependents.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default NewDependents;
