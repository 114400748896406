import {
  GET_BENEFITS_FAIL,
  GET_BENEFITS_SUCCESS,
  ADD_BENEFIT_SUCCESS,
  ADD_BENEFIT_FAIL,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  DELETE_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  product: {},
  benefits: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true
};

const Benefit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: action.payload,
        loading: true
      };

    case GET_BENEFITS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: [...state.benefits, action.payload],
      };

    case ADD_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: state.benefits.map(benefit =>
          (benefit.id + '') === (action.payload.id + '')
            ? { benefit, ...action.payload }
            : benefit
        ),
      };

    case UPDATE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_BENEFIT_SUCCESS:
      return {
        ...state,
        benefits: state.benefits.filter(
          benefit => benefit.id !== action.payload
        ),
      };
    case DELETE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Benefit;
