
/* RISK_CLASSS */
export const GET_RISK_CLASSS = "GET_RISK_CLASSS"
export const GET_RISK_CLASSS_SUCCESS = "GET_RISK_CLASSS_SUCCESS"
export const GET_RISK_CLASSS_FAIL = "GET_RISK_CLASSS_FAIL"

/**
 * add RISK_CLASS
 */
export const ADD_NEW_RISK_CLASS = "ADD_NEW_RISK_CLASS"
export const ADD_RISK_CLASS_SUCCESS = "ADD_RISK_CLASS_SUCCESS"
export const ADD_RISK_CLASS_FAIL = "ADD_RISK_CLASS_FAIL"

/**
 * Edit RISK_CLASS
 */
export const UPDATE_RISK_CLASS = "UPDATE_RISK_CLASS"
export const UPDATE_RISK_CLASS_SUCCESS = "UPDATE_RISK_CLASS_SUCCESS"
export const UPDATE_RISK_CLASS_FAIL = "UPDATE_RISK_CLASS_FAIL"

/**
 * Delete RISK_CLASS
 */
export const DELETE_RISK_CLASS = "DELETE_RISK_CLASS"
export const DELETE_RISK_CLASS_SUCCESS = "DELETE_RISK_CLASS_SUCCESS"
export const DELETE_RISK_CLASS_FAIL = "DELETE_RISK_CLASS_FAIL"

