import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  addNewBenefit as onAddNewBenefit,
  updateBenefit as onUpdateBenefit,
} from "store/actions";

import {
  BenefitId,
  BillingName}
  from "./BenefitCol";
import { useMutation, useQueryClient } from 'react-query';


//redux
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
// import BenefitsModal from "./BenefitsModal";

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { fetchAuditsApi } from 'api/setup';


function Audits() {
  const [pages, setPages] = useState(0);
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: false,
    searchColumns: []
  });

  const benefitsCardData = [
    { title: "Total Benefits", iconClass: "bx bx-group", description: "21,318" },
    { title: "Active Benefits", iconClass: "bx bx-trophy", description: "42,178" },
    {
      title: "Non Active Benefits",
      iconClass: "bx bx-dislike",
      description: "516,899",
    },
  ];

  const { mutate: mutateAudits,
    isLoading: isLoadingAudits,
    data: audits, } = useMutation(
      (payload) => fetchAuditsApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  //meta title
  document.title = "Audits | SUN Welfare Administration System";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setBenefit] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Benefit Id"
        ).required("Please Enter Your Benefit Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Benefit Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBenefit = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateBenefit(updateBenefit));
        validation.resetForm();
      } else {
        const newBenefit = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewBenefit(newBenefit));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.benefit;
  const EcommerceBenefitProperties = createSelector(
    selectEcommerceState,
    (benefit) => ({
      groups: [],
      // TO-DO MAKE SURE TO => return this for live data
      // groups: Ecommerce.groups,
      loading: benefit.loading
    })
  );

  // const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    mutateAudits({ ...pagination, pageNumber: pagination.pageNumber - 1 });
  }, [pagination]);

  const onChangePagination = (
    { page, limit }, filters, sorter) => {

    console.log(filters)
    let searchColumns = isEmpty(filters) ? [] : [
      filters?.id && {
        id: filters?.id
      },
      filters?.applicationNumber && {
        applicationNumber: filters?.applicationNumber
      }
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setBenefit(null);
    } else {
      setModal(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timestamp',
        filterable: true,
        Cell: (cellProps) => {
          const dateValue = cellProps.value;
          const formattedDate = dateValue ? moment(dateValue).format('YYYY MMM DD h:mm A') : '';
          return <span>{formattedDate}</span>;
        }
      },
      {
        Header: 'PRIINCIPAL',
        accessor: 'principal',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <BenefitId {...cellProps} />;
        }
      },
      {
        Header: 'EVENT TYPE',
        accessor: 'type',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'DATA',
        accessor: 'data.message',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      // {
      //   Header: 'DATE CREATED',
      //   accessor: 'data',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Date {...cellProps} />;
      //   }
      // },
    ],
    []
  );

  return (
    <React.Fragment>
      {/* <BenefitsModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="AuditLogs" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    // showDelete
                    // showEdit
                    // showView
                    columns={columns}
                    data={audits?.list?.reverse() || []}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    // hasCreate
                    isPagination={true}
                    filterable={false}
                    resource="auditLogs"
                    pages={pages}
                    iscustomPageSizeOptions={true}
                    addText=""
                    loading={isLoadingAudits}
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    paginationData={pagination}
                    onChangePagination={onChangePagination}
                    searchByNames={[
                      {
                        value: 'email',
                        text: t('common.email'),
                      },
                      {
                        value: 'idNumber',
                        text: t('common.idNumber'),
                      },
                      {
                        value: 'applicationNumber',
                        text: t('applications.applicationNumber'),
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Audits.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Audits;
