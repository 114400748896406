import React, { useEffect, useState } from 'react';
import {
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Alert,
    FormFeedback,
    Button
} from "reactstrap";
import moment from 'moment'
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { updateMember, addMember } from 'api/member';
import { getAllGroupApi } from 'api/group';
import { isEmpty } from 'lodash';
import { useRole } from 'hooks/useRole';
import Select from 'react-select';


const MemberDetailsTab = ({ onNext, address, member, onChangeMemberId = () => { }, onSetMember, mutateMember }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const isNew = isEmpty(id);
    const queryClient = useQueryClient();

    const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, address: userAddress } = useRole();

    // Define validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string().required("Please Enter First Name"),
        lastName: Yup.string().required("Please Enter Last Name"),
        identificationType: Yup.string().required("Please Select ID Type"),
        identificationNumber: Yup.string()
            .required("Please Enter ID")
            .max(9, "ID cannot be more than 9 digits"),
        mobileNumber1: Yup.string(
        ).required("Please Enter Mobile Number")
            .matches(/^\d+$/, "Phone number must contain only digits"),
        gender: Yup.string().required("Please Select Gender"),
        dob: Yup.string().required("Please Select DOB"),
        joinType: Yup.string().required("Please Select Joining Type"),
        membership: Yup.string().required("Please Select Joining Type"),
        groupId: Yup.string().when('joinType', {
            is: (v) => v === 'Group',
            then: (schema) => schema.required('Please Select Group')
        })
    });

    // Initialize formik
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: member?.firstName || '',
            lastName: member?.lastName || '',
            email: member?.email || '',
            identificationType: member?.identificationType || '',
            identificationNumber: member?.identificationNumber || '',
            mobileNumber1: member?.mobileNumber1 || '',
            mobileNumber2: member?.mobileNumber2 || '',
            gender: member?.gender || '',
            dob: member?.dob ? moment(member?.dob).format('DD-MM-YYYY') : '',
            joinType: member?.joinType || '',
            groupId: member?.groupId || '',
            showGroup: false,
            membership: member?.membership || 'New',
            groupId: member?.groupId || ''
        },
        validationSchema,
        onSubmit: async ({ dob, ...values }) => {
            let memberId = null;
            if (!(member?.id || member?.memberId)) {
                const data = {
                    ...values,
                    dob: moment(dob).format("DD-MM-YYYY"),
                    source: member?.source || "Web",
                    status: 'Draft',
                    address
                }
                await addMember(data).then(res => {
                    memberId = res?.data;
                    mutateMember(memberId);
                    onNext();
                }).catch(err => {
                    setErrorMessage(err?.message)
                });
                // onSetMember(data);
            } else {
                const data = {
                    ...member,
                    ...values,
                    memberId: member?.memberId,
                    dob: moment(dob).format("DD-MM-YYYY"),
                    source: member?.source || "Web",
                    status: 'Draft',
                    address,
                }
                await updateMember(data).then(res => {
                    memberId = res.data?.id;
                    onNext();
                }).catch(err => {
                    setErrorMessage(err?.message)
                });
            }
            onChangeMemberId(memberId);
        },
    });

    const { mutate: mutateGroups, isLoading: isLoadingGroups, data: groupsData } = useMutation(
        (payload) => getAllGroupApi(payload),
        {
            onSuccess: res => {
                console.log(res);
            },
            onSettled: () => {
                queryClient.invalidateQueries('get-all-counties');
            },
        }
    );

    const groupOptions = groupsData?.list.map(item => ({
        value: item.id,
        label: item.name
    })) || [];

    useEffect(() => {
        if (member) {
            onChangeMemberId(member.memberId);
        }
        if (member?.memberId) {
            onChangeMemberId(member?.memberId);
        }
    }, [member]);

    return (
        <Form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                {errorMessage && errorMessage ? (
                    <Alert color="danger">{errorMessage}</Alert>
                ) : null}
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="firstName">
                            First Name
                        </Label>
                        <Input
                            name="firstName"
                            type="text"
                            className="form-control"
                            id="firstName"
                            placeholder="Enter Your First Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                                validation.touched.firstName && validation.errors.firstName ? true : false
                            }
                        />
                        {validation.touched.firstName && validation.errors.firstName ? (
                            <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="lastName">
                            Last Name
                        </Label>
                        <Input
                            name="lastName"
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Enter Your Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                                validation.touched.lastName && validation.errors.lastName ? true : false
                            }
                        />
                        {validation.touched.lastName && validation.errors.lastName ? (
                            <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='identificationType'>Identification Type</Label>
                        <Input type='select' id='identificationType' className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="identificationType"
                            value={validation.values.identificationType || ""}
                            invalid={
                                validation.touched.identificationType && validation.errors.identificationType ? true : false
                            }

                        >
                            <option defaultValue>
                                Select ID Type...
                            </option>
                            <option value="idNumber">National ID Card</option>
                            <option value="Passport">Passport</option>
                        </Input>
                        {validation.touched.identificationType && validation.errors.identificationType ? (
                            <FormFeedback type="invalid">{validation.errors.identificationType}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="identificationNumber">
                            Identification Number
                        </Label>
                        <Input
                            name="identificationNumber"
                            type="text"
                            className="form-control"
                            id="identificationNumber"
                            placeholder="Enter Your Identification Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.identificationNumber || ""}
                            invalid={
                                validation.touched.identificationNumber && validation.errors.identificationNumber ? true : false
                            }
                        />
                        {validation.touched.identificationNumber && validation.errors.identificationNumber ? (
                            <FormFeedback type="invalid">{validation.errors.identificationNumber}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="dob">
                            Date Of birth
                        </Label>
                        <Input
                            name="dob"
                            max={moment().subtract(18, "years").format("DD-MM-YYYY")}
                            type="date"
                            className="form-control"
                            id="dob"
                            placeholder="Select Date of Birth"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dob || ""}
                            invalid={
                                validation.touched.dob && validation.errors.dob ? true : false
                            }
                        />
                        {validation.touched.dob && validation.errors.dob ? (
                            <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='gender'>Sex</Label>
                        <Input type='select' className="form-select"
                            name="gender"
                            id='gender'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gender || ""}
                            invalid={
                                validation.touched.gender && validation.errors.gender ? true : false
                            }
                        >
                            <option value="" disabled>
                                Select Gender...
                            </option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Intersex">Intersex</option>
                            <option value="Other">Other</option>
                        </Input>
                        {validation.touched.gender && validation.errors.gender ? (
                            <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="mobileNumber1">
                            Phone Number
                        </Label>
                        <Input
                            name="mobileNumber1"
                            type="text"
                            className="form-control"
                            id="mobileNumber1"
                            placeholder="Enter Your Mobile Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNumber1 || ""}
                            invalid={
                                validation.touched.mobileNumber1 && validation.errors.mobileNumber1 ? true : false
                            }
                        />
                        {validation.touched.mobileNumber1 && validation.errors.mobileNumber1 ? (
                            <FormFeedback type="invalid">{validation.errors.mobileNumber1}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="mobileNumber2">
                            Alternate Phone Number
                        </Label>
                        <Input
                            name="mobileNumber2"
                            type="number"
                            className="form-control"
                            id="mobileNumber2"
                            placeholder="Enter Alternate Mobile Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNumber2 || ""}

                        />
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for="email">
                            Enter Email Address
                        </Label>
                        <Input
                            name="email"
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Email Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                        />

                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='joinType'>Joining Type</Label>
                        <Input
                            type='select'
                            className="form-select"
                            name="joinType"
                            id='joinType'
                            onChange={(e) => {
                                const value = e.target.value;
                                validation.setFieldValue('joinType', value);

                                if (value === "Group") {
                                    validation.setFieldValue('showGroup', true);
                                    if (address?.wardId || member?.address?.wardId) {
                                        mutateGroups({
                                            pageSize: 2000,
                                            pageNumber: 0,
                                            sortBy: "name",
                                            searchColumns: [{ "name": "wardId", "value": member?.address?.wardId || address?.wardId }]
                                        });
                                    }
                                } else {
                                    validation.setFieldValue('showGroup', false);
                                }
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.joinType || ""}
                            invalid={validation.touched.joinType && validation.errors.joinType ? true : false}
                        >
                            <option value="" defaultValue>
                                Select an option...
                            </option>
                            <option value="Individual">Indivudual</option>
                            <option value="Group">Group</option>
                        </Input>
                        {validation.touched.joinType && validation.errors.joinType ? (
                            <FormFeedback type="invalid">{validation.errors.joinType}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup className="mb-3">
                        <Label for='membership'>Membership</Label>
                        <Input type='select' className="form-select"
                            name="membership"
                            id='membership'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.membership || ""}
                            invalid={
                                validation.touched.membership && validation.errors.membership ? true : false
                            }
                        >
                            <option value="" defaultValue>
                                Select an option...
                            </option>
                            <option value="New">New</option>
                            <option value="Old">Old</option>
                        </Input>
                        {validation.touched.membership && validation.errors.membership ? (
                            <FormFeedback type="invalid">{validation.errors.membership}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </Col>
                {validation.values.joinType === "Group" && (
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for='groupId'>Select Group</Label>
                            <Select
                                id='groupId'
                                name="groupId"
                                options={groupOptions}
                                value={groupOptions.find(option => option.value === validation.values.groupId)}
                                onChange={(selectedOption) => validation.setFieldValue("groupId", selectedOption?.value)}
                                onBlur={validation.handleBlur}
                                className={validation.touched.groupId && validation.errors.groupId ? 'is-invalid' : ''}
                            />
                            {validation.touched.groupId && validation.errors.groupId && (
                                <FormFeedback type="invalid">{validation.errors.groupId}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <Row className="mt-4">
                <Col md="6">
                    <FormGroup>
                        <Button
                            type="submit"
                            color="primary"
                            block
                        >
                            SAVE DRAFT
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default MemberDetailsTab
