import { get, post, put } from './utils';

export async function getAllAllMembersApi(data) {
    return post(`/api/um/member/search`, data);
}

export async function getMemberByIdApi(memberId) {
    return get(`/api/um/member/?memberId=${memberId}`);
}

export async function getMemberByIdNumberApi(idNumber) {
    return get(`/api/um/member/identification`, { idNumber });
}

export async function addMember(data) {
    return post(`/api/um/admin/application/register`, data);
}

export async function updateMember(data) {
    return put(`/api/um/member/update`, data);
}

export async function addNextOfKin(data) {
    return post(`/api/um/nextofkin/save`, data);
}

export async function editNextOfKin(data) {
    return post(`/um/nextofkin/apply`, data);
}

export async function addDependant(data) {
    return post(`/api/um/dependent/save`, data);
}

export async function updateDependant(data) {
    return put(`/api/um/dependent/update`, data);
}

export async function updateNextOfKin() {
    return put(`/api/um/nextofkin/update`, data);
}

export async function getNextOfKinApi(data) {
    return get(`/api/um/nextofkin/`, data);
}

export async function getNextOfKinByIdApi(data) {
    return get(`/api/um/nextofkin/?memberId=${data?.memberId}&status=${data?.status}`);
}

export async function getDependantsByIdApi(memberId) {
    return get(`/api/um/dependent/?memberId=${memberId}`);
}
export async function getAllNewDependentsApi(data) {
    return post(`/api/um/dependent/search`, data);
}

export async function getAllKinApplicationsApi(data) {
    return post(`/api/um/nextofkin/applications`, data);
}

export async function getDepApplicationByIdApi(data) {
    return get(`/api/um/dependent/application`, data);
}

export async function getNOKApplicationByIdApi(data) {
    return get(`/api/um/nextofkin/application`, data);
}

export async function approveNOKApplicationApi(data) {
    return post(`/api/um/nextofkin/approve`, data);
}

export async function rejectNOKApplicationApi(data) {
    return post(`/api/um/nextofkin/reject`, data);
}

export async function getDependantsApi(data) {
    return get(`/api/um/dependent/`, data);
}

export async function getAttachmentApi(id) {
    return get(`/api/um/dependent/attachment/?dependentId=${id}`);
}

export async function approveDepeApplicationApi(data) {
    return post(`/api/um/dependent/approve`, data);
}

export async function rejectDepeApplicationApi(data) {
    return post(`/api/um/dependent/reject`, data);
}

const token = localStorage.getItem('sessionToken');

export async function uploadDepeMedia(entityId, data) {
    let url = `${process.env.REACT_APP_SERVER_URL}/shofco/api/um/dependent/attachment/add?dependentId=${entityId}`;
    const formData = new FormData();
    formData.append('file', data);
    if (token) {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        }).then((res) => res.json());
    }
}