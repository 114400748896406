import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup, Col,

    Row,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import moment from 'moment'
import { useFormik } from "formik";
import { addMember, addNextOfKin, updateNextOfKin, uploadDepeMedia } from 'api/member';

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const AddNextOfKinModal = ({ visible, relationships, isNew, member, initData, setVisible }) => {
    const [file, setSelectedFile] = useState(null)

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            relationship: '',
            identificationType: '',
            identificationNumber: '',
            mobileNumber: '',
            gender: '',
            dob: '',
            // nextofkinIdentification: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter First Name"),
            lastName: Yup.string().required("Please Enter Last Name"),
            // email: Yup.string().email('Please enter a valid email address').required('Email is required'),
            identificationType: Yup.string().required("Please Select ID Type"),
            identificationNumber: Yup.string().required("Please Enter ID"),
            mobileNumber: Yup.string().required("Please Enter Mobile Number"),
            relationship: Yup.string().required("Please Select Relationship"),
            gender: Yup.string().required("Please Select Gender"),
            dob: Yup.string().required("Please Select DOB"),
            // nextofkinIdentification: Yup.string().required("Please Select ID document"),
        }),
        onSubmit: async ({ nextofkinIdentification, ...values }, { resetForm }) => {
            let memberData = {}
            if (!isNew) {
                memberData = member;
            } else {
                memberData = member;
            }
            const data = {
                ...values,
                memberId: member?.memberId,
                dob: moment(values.dob).format('DD-MM-YYYY')
            }
            let kinId = null;
            if (!member?.nextOfKin) {
                await addNextOfKin(data).then(res => {
                    if (res.status === 'Success') {
                        kinId = res?.data?.id
                        setVisible(false);
                        resetForm();
                    }
                    else {
                    }

                });
            } else {
                data['memberId'] = member?.nextOfKin?.id
                await updateNextOfKin(data).then(res => {
                    kinId = res?.data?.id
                    onNext();
                });
            }
            kinId && uploadDepeMedia(kinId, file);
        },
    });
    const handleAcceptedFiles = files => {
        files.forEach(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
    }

    const handleFileChange = e => {
        setSelectedFile(e.currentTarget.files[0]);
        validation.handleChange(e);
    };

    return (
        <Modal
            size='lg'
            isOpen={visible}
            toggle={setVisible}
        >
            <ModalHeader toggle={setVisible}>Add Next of KIN</ModalHeader>
            <ModalBody>
                <Form autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="firstName">
                                    First Name
                                </Label>
                                <Input
                                    name="firstName"
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={
                                        validation.touched.firstName && validation.errors.firstName ? true : false
                                    }
                                />
                                {validation.touched.firstName && validation.errors.firstName ? (
                                    <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="lastName">
                                    Last Name
                                </Label>
                                <Input
                                    name="lastName"
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={
                                        validation.touched.lastName && validation.errors.lastName ? true : false
                                    }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label>Gender</Label>
                                <Input type='select'
                                    className="form-select"
                                    name="gender"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gender || ""}
                                    invalid={
                                        validation.touched.gender && validation.errors.gender ? true : false
                                    }
                                >
                                    <option defaultValue>
                                        Select Gender...
                                    </option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                    <option value="Intersex">Intersex</option>
                                    <option value="Other">Other</option>
                                </Input>
                                {validation.touched.gender && validation.errors.gender ? (
                                    <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="email">
                                    Enter Email Address
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email Address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                // invalid={
                                //     validation.touched.email && validation.errors.email ? true : false
                                // }
                                />
                                {/* {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null} */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label>Identification Type</Label>
                                <Input type='select' className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="identificationType"
                                    value={validation.values.identificationType || ""}
                                    invalid={
                                        validation.touched.identificationType && validation.errors.identificationType ? true : false
                                    }

                                >
                                    <option defaultValue>
                                        Select ID Type...
                                    </option>
                                    <option value="idNumber">National ID Card</option>
                                    <option value="Passport">Passport</option>
                                </Input>
                                {validation.touched.identificationType && validation.errors.identificationType ? (
                                    <FormFeedback type="invalid">{validation.errors.identificationType}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                    Identification Number
                                </Label>
                                <Input
                                    name="identificationNumber"
                                    type="text"
                                    className="form-control"
                                    id="basicpill-pancard-input5"
                                    placeholder="Enter Your Identification Number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.identificationNumber || ""}
                                    invalid={
                                        validation.touched.identificationNumber && validation.errors.identificationNumber ? true : false
                                    }
                                />
                                {validation.touched.identificationNumber && validation.errors.identificationNumber ? (
                                    <FormFeedback type="invalid">{validation.errors.identificationNumber}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="mobileNumber">
                                    Phone Number
                                </Label>
                                <Input
                                    name="mobileNumber"
                                    type="text"
                                    className="form-control"
                                    id="mobileNumber"
                                    placeholder="Enter Next of Kin Mobile Number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.mobileNumber || ""}
                                    invalid={
                                        validation.touched.mobileNumber && validation.errors.mobileNumber ? true : false
                                    }
                                />
                                {validation.touched.mobileNumber && validation.errors.mobileNumber ? (
                                    <FormFeedback type="invalid">{validation.errors.mobileNumber}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label>Relationship</Label>
                                <Input className="form-select"
                                    name="relationship"
                                    type='select'
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.relationship || ""}
                                    invalid={
                                        validation.touched.relationship && validation.errors.relationship ? true : false
                                    }
                                >
                                    <option defaultValue value={''}>
                                        Select Relationship...
                                    </option>
                                    {
                                        relationships && relationships?.map(item => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))
                                    }
                                </Input>
                                {validation.touched.relationship && validation.errors.relationship ? (
                                    <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                    Date Of birth
                                </Label>
                                <Input
                                    name="dob"
                                    max={moment().subtract(18, "years").format("DD-MM-YYYY")}
                                    type="date"
                                    className="form-control"
                                    id="basicpill-pancard-input5"
                                    placeholder="Select Date of Birth"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dob || ""}
                                    invalid={
                                        validation.touched.dob && validation.errors.dob ? true : false
                                    }
                                />
                                {validation.touched.dob && validation.errors.dob ? (
                                    <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="nextofkinIdentification">
                                    Upload Next of KIN Identification Document
                                </Label>
                                <Input
                                    type="file"
                                    name="nextofkinIdentification"
                                    className="form-control"
                                    id="nextofkinIdentification"
                                    placeholder="Upload Next of KIN Identification Document"
                                    onChange={handleFileChange}
                                    onBlur={validation.handleBlur}
                                // invalid={
                                //     validation.touched.nextofkinIdentification && validation.errors.nextofkinIdentification ? true : false
                                // }
                                />
                                {/* {validation.touched.nextofkinIdentification && validation.errors.nextofkinIdentification ? (
                                    <FormFeedback type="invalid">{validation.errors.nextofkinIdentification}</FormFeedback>
                                ) : null} */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <FormGroup className="mt-4">
                            <Button
                                type="submit"
                                color="primary"
                                block
                            >
                                SAVE NEXT OF KIN
                            </Button>
                        </FormGroup>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={setVisible}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

AddNextOfKinModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    member: PropTypes.object,
    relationships: PropTypes.array,
};

export default AddNextOfKinModal;
