import { get, post } from './utils';

export async function getAllTransactionApi(data) {
    return post(`/api/transaction/search`, data);
}

export async function getAllInvoicesApi(data) {
    return post(`/api/transaction/invoice/search`, data);
}

export async function getAllReceiptsApi(data) {
    return post(`/api/transaction/receipt/search`, data);
}

export async function requestPayment(data) {
    return post(`/api/payment`, data);
}

export async function createPayment(data) {
    return post(`/callbacks/offline`, data);
}

export async function checkPaymentStatus(data) {
    return post(`/api/payment/status`, data);
}

export async function getInvoiceDetailsByIdApi(invoiceId) {
    return get(`/api/transaction/invoice/id?invoiceId=${invoiceId}`);
}

export async function getReceiptDetailsByIdApi(id) {
    return get(`/api/transaction/receipt/id?id=${id}`);
}

