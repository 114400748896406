import {
  GET_MEMBERS,
  GET_MEMBER,
  GET_MEMBERS_FAIL,
  GET_MEMBER_FAIL,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_SUCCESS,
  ADD_NEW_MEMBER,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  SET_MEMBER,
  EDIT_MEMBER
} from "./actionTypes";

export const getMembers = () => ({
  type: GET_MEMBERS,
})

export const getMember = (id) => ({
  type: GET_MEMBER,
  payload: id,
})

export const getMembersSuccess = members => ({
  type: GET_MEMBERS_SUCCESS,
  payload: members,
})

export const getMembersFail = error => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
})

export const getMemberSuccess = member => ({
  type: GET_MEMBER_SUCCESS,
  payload: member,
})

export const getMemberFail = error => ({
  type: GET_MEMBER_FAIL,
  payload: error,
})

export const addNewMember = member => ({
  type: ADD_NEW_MEMBER,
  payload: member,
})

export const addMemberSuccess = member => ({
  type: ADD_MEMBER_SUCCESS,
  payload: member,
})

export const addMemberFail = error => ({
  type: ADD_MEMBER_FAIL,
  payload: error,
})

export const updateMember = member => ({
  type: UPDATE_MEMBER,
  payload: member,
})

export const updateMemberSuccess = member => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: member,
})

export const updateMemberFail = error => ({
  type: UPDATE_MEMBER_FAIL,
  payload: error,
})

export const deleteMember = member => ({
  type: DELETE_MEMBER,
  payload: member,
})

export const deleteMemberSuccess = member => ({
  type: DELETE_MEMBER_SUCCESS,
  payload: member,
})

export const deleteMemberFail = error => ({
  type: DELETE_MEMBER_FAIL,
  payload: error,
})
export const setMember = (data) => ({
  type: SET_MEMBER,
  payload: data,
})

export const editMember = (data, type) => ({
  type: EDIT_MEMBER,
  payload: { data, type },
})