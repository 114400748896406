
/* COMMUNICATIONS */
export const GET_COMMUNICATIONS = "GET_COMMUNICATIONS"
export const GET_COMMUNICATIONS_SUCCESS = "GET_COMMUNICATIONS_SUCCESS"
export const GET_COMMUNICATIONS_FAIL = "GET_COMMUNICATIONS_FAIL"

/**
 * add COMMUNICATION
 */
export const ADD_NEW_COMMUNICATION = "ADD_NEW_COMMUNICATION"
export const ADD_COMMUNICATION_SUCCESS = "ADD_COMMUNICATION_SUCCESS"
export const ADD_COMMUNICATION_FAIL = "ADD_COMMUNICATION_FAIL"

/**
 * Edit COMMUNICATION
 */
export const UPDATE_COMMUNICATION = "UPDATE_COMMUNICATION"
export const UPDATE_COMMUNICATION_SUCCESS = "UPDATE_COMMUNICATION_SUCCESS"
export const UPDATE_COMMUNICATION_FAIL = "UPDATE_COMMUNICATION_FAIL"

/**
 * Delete COMMUNICATION
 */
export const DELETE_COMMUNICATION = "DELETE_COMMUNICATION"
export const DELETE_COMMUNICATION_SUCCESS = "DELETE_COMMUNICATION_SUCCESS"
export const DELETE_COMMUNICATION_FAIL = "DELETE_COMMUNICATION_FAIL"

