export const ROLES = {
    Facilitator: "Facilitator",
    CountyCoordinator: "County Coordinator",
    SiteCoordinator: "Site Coordinator",
    FinanceOfficer: "Finance Officer",
    Admin: "Admin",
    SuperAdmin: "Super Admin",
    Director: "Director",
    ClusterLeader:"Cluster Leader",
    Data:"Data"
}

