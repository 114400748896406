import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";

import EcommerceOrdersModal from "./EcommerceOrdersModal";
import { latestTransaction } from "../../common/data/dashboard";


import TableContainer from "../Common/TableContainer";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";
import { useMutation, useQueryClient } from 'react-query';
import { getAllGroupMembersApi } from 'api/group';
import { useNavigate } from "react-router-dom";

const GroupMembers = ({ groupId }) => {

  const navigate = useNavigate()
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState({
    pageSize: 150,
    pageNumber: 1,
    sortBy: 'id',
    ascendingSort: true,
    searchColumns: []
  });

  const [modal1, setModal1] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);
  const [pages, setPages] = useState(0);

  const { mutate: mutateGroupMembers,
    isLoading: isLoadingGroupMembers,
    data: groupMembersData, } = useMutation(
      (payload) => getAllGroupMembersApi(payload),
      {
        onSuccess: res => {
          res.pages > 0 && setPages(res.pages);
        },
        onSettled: () => {
          // setLoading(false);
          queryClient.invalidateQueries('/um/group/member/all');
        },
      }
    );

  useEffect(() => {
    if (groupId) {
      mutateGroupMembers({ groupId });
    }

  }, [groupId]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Full Names",
        accessor: (row) => `${row?.firstName || ''} ${row?.middleName && row?.middleName !== 'NULL' ? row?.middleName : ''} ${row?.lastName && row?.lastName !== 'NULL' ? row?.lastName : ''}`,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Identification Number",
        accessor: "identificationNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Mobile No",
        accessor: "mobileNumber1",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      // {
      //   Header: "EMAIL",
      //   accessor: "email",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <PaymentMethod {...cellProps} />;
      //   },
      // },
      {
        Header: "Gender",
        accessor: "gender",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
    ],
    []
  );

  const onChangePagination = (
    { page, limit }, filters, sorter) => {
    let searchColumns = isEmpty(filters) ? pagination.searchColumns : [
      ...pagination.searchColumns,
      ...(filters?.groupName ? [
        {
          "name": "groupName",
          "value": filters?.groupName
        }] : []),
      ...(filters?.groupNumber ? [
        {
          "name": "groupNumber",
          "value": filters?.groupNumber
        }] : []),
    ];
    setPagination({ ...pagination, pageNumber: page || pagination.pageNumber, pageSize: limit || pagination.pageSize, searchColumns })
  }

  const handleView = data => {
    console.log(data)
    navigate('/members/' + data?.memberId)
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Group Members</div>
          <TableContainer
            columns={columns}
            data={groupMembersData?.members || []}
            isGlobalFilter={false}
            isAddOptions={false}
            pages={pages}
            isPagination={true}
            iscustomPageSizeOptions={false}
            customPageSize={4}
            onChangePagination={onChangePagination}
            handleView={handleView}
            // showEdit
            paginationData={pagination}
            showView
            pagination="pagination pagination-rounded justify-content-end mb-2"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

GroupMembers.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(GroupMembers);
