import {
  GET_BENEFIT_TYPES_FAIL,
  GET_BENEFIT_TYPES_SUCCESS,
  ADD_BENEFIT_TYPE_SUCCESS,
  ADD_BENEFIT_TYPE_FAIL,
  UPDATE_BENEFIT_TYPE_SUCCESS,
  UPDATE_BENEFIT_TYPE_FAIL,
  DELETE_BENEFIT_TYPE_SUCCESS,
  DELETE_BENEFIT_TYPE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  product: {},
  benefitTypes: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true
};

const BenefitType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BENEFIT_TYPES_SUCCESS:
      return {
        ...state,
        benefitTypes: action.payload,
        loading: true
      };

    case GET_BENEFIT_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_BENEFIT_TYPE_SUCCESS:
      return {
        ...state,
        benefitTypes: [...state.benefitTypes, action.payload],
      };

    case ADD_BENEFIT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_BENEFIT_TYPE_SUCCESS:
      return {
        ...state,
        benefitTypes: state.benefitTypes.map(benefitType =>
          (benefitType.id + '') === (action.payload.id + '')
            ? { benefitType, ...action.payload }
            : benefitType
        ),
      };

    case UPDATE_BENEFIT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_BENEFIT_TYPE_SUCCESS:
      return {
        ...state,
        benefitTypes: state.benefitTypes.filter(
          benefitType => benefitType.id !== action.payload
        ),
      };
    case DELETE_BENEFIT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default BenefitType;
