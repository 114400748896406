import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { StoreContext, useStore } from 'hooks/useStore';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';

import { getMemberStatusCountsApi, getGroupStatusCountsApi, getClaimStatusCountsApi, getApplicationStatusCountsApi, getPaymentsStatusCountsApi } from 'api/report';
import { setMemberSummary, setGroupSummary, setClaimSummary, setApplicationSummary, setPaymentSummary } from 'store/actions'

const Authmiddleware = (props) => {
  const { isAuthenticated } = useAuth();
  const { isFacilitator, isSiteCord, isCountyCoordinator, isClusterLeader, address } = useRole();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const dateFormat = 'DD-MM-YYYY';

  const startDate = moment().startOf('month').format(dateFormat);
  const endDate = moment().format(dateFormat);

  // const { wardId, constituencyId, countyId, regionId } = isAuthenticated ? JSON.parse(localStorage.getItem('address')) : {};

  const addressData = isAuthenticated ? (isFacilitator ? { wardId: address?.wardId } : isSiteCord ? { constituencyId: address?.constituencyId } : isCountyCoordinator ? { countyId: address?.countyId } : isClusterLeader ? { regionId: address?.regionId } : {}) : {}

  if (!localStorage.getItem("sessionToken")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  const { mutate: mutateMemberCounts,
    isLoading: isLoadingProducts,
    data: memberSummary, } = useMutation(
      (payload) => getMemberStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setMemberSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateGroupCounts,
    data: groupSummary, } = useMutation(
      (payload) => getGroupStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setGroupSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateClaimCounts,
    data: claimSummary, } = useMutation(
      (payload) => getClaimStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setClaimSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutateApplicationCounts,
    data: applicationSummary, } = useMutation(
      (payload) => getApplicationStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setApplicationSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const { mutate: mutatePaymentCounts,
    data: paymentsSummary, } = useMutation(
      (payload) => getPaymentsStatusCountsApi(payload),
      {
        onSuccess: res => {
          dispatch(setPaymentSummary(res?.data));
        },
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  useEffect(() => {
  }, [])

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

Authmiddleware.propTypes = {
  location: PropTypes.any,
  children: PropTypes.any,

};

export default Authmiddleware;
