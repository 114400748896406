import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import logo from "../Invoices/logo-dark2.png";
import moment from "moment";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from 'react-router-dom';
import { getInvoiceDetailsByIdApi, requestPayment } from 'api/transaction';
import { getMemberByIdApi } from 'api/member';
import { useRole } from 'hooks/useRole';

const formatPhoneNumber = (phoneNumber) => {
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  if (cleanedNumber.startsWith('0')) {
    return `254${cleanedNumber.substring(1)}`;
  } else if (cleanedNumber.startsWith('+254')) {
    return `254${cleanedNumber.substring(4)}`;
  } else if (cleanedNumber.startsWith('254')) {
    return cleanedNumber;
  }
  return cleanedNumber;
};

const InvoiceDetail = () => {
  const { isData } = useRole();

  const { id } = useParams();

  const [invoiceData, setInvoiceData] = useState({});
  const [memberData, setMemberData] = useState({});

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const response = await getInvoiceDetailsByIdApi(id);
        setInvoiceData(response);
  
        console.log("Invoice Response:", response);
        const memberId = response?.toMemberId;
  
        if (memberId) {
          const memberResponse = await getMemberByIdApi(memberId);
          setMemberData(memberResponse);
          console.log("Member Response:", memberResponse);
        }
      } catch (error) {
        console.error('Error fetching invoice details:', error);
      }
    };
  
    fetchInvoiceDetails();
  }, [id]);  

  const calculateSubtotal = () => {
    if (!invoiceData.items || invoiceData.items.length === 0) {
      return 0;
    }
    return invoiceData.items.reduce((acc, item) => acc + item.totalAmount, 0);
  };

  const calculateCostPerUnitTotal = () => {
    if (!invoiceData.items || invoiceData.items.length === 0) {
      return 0;
    }
    return invoiceData.items.reduce((acc, item) => acc + item.costPerUnit, 0);
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const total = subtotal + (subtotal * invoiceData.taxAmount) / 100;
    return total.toFixed(2);
  };

  const calculateBalance = () => {
    const costPerUnitTotal = calculateCostPerUnitTotal();
    const subtotal = calculateSubtotal();
    return (costPerUnitTotal - subtotal).toFixed(2);
  };

  const payForInvoice = async () => {
    const total = calculateTotal();
    try {
      await requestPayment({
        userId: invoiceData.toMemeberId,
        clientMobile: formatPhoneNumber(memberData.mobileNumber1),
        description: "SUN WELFARE Payment",
        amount: total,
        platform: "WEB",
        paymentMethod: "MPESA"
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Invoice" breadcrumbItem="Details" />
          <Link to="/invoices">
            <Button type="button" color="primary" className="btn">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>

          <Card style={{ maxWidth: "40%", margin: "0 auto" }}>
            <CardBody>
              <CardTitle className="mb-4">Invoice</CardTitle>

              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <img src={logo} alt="Company Logo" style={{ maxWidth: "200px" }} />
                  </div>
                </Col>
                {!isData
                  && <Col md={6} className="text-end">
                    <Button color="primary" onClick={payForInvoice}>Pay Now</Button>
                  </Col>}
              </Row>

              <Row>
                <Col md={6}>
                  <div>
                    <h6>Invoice To:</h6>
                    <p>{`${memberData.firstName} ${memberData.lastName}`}</p>
                    <p>Invoice No: {invoiceData.invoiceNumber}</p>
                    <p>Policy No: {invoiceData.policyNumber}</p>
                    {memberData.address && (
                      <>
                        <p>Ward: {memberData.address.wardName}</p>
                        <p>constituency: {memberData.address.constituencyName}</p>
                      </>
                    )}
                    <p>Phone: {memberData.mobileNumber1}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <h3 className={`text-${invoiceData.status === "Fully_Paid" ? "success" : invoiceData.status === "Partially_Paid" ? "warning" : "danger"}`}>{invoiceData.status}</h3>
                    <h6>Invoice From:</h6>
                    <p>SUN WELFARE</p>
                    <p>Kibera</p>
                    <p>Gatwekera Village</p>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={6}>
                  <div>
                    <h6>Due Date:</h6>
                    <p>{moment(invoiceData.dueDate).format('YYYY MMM DD h:mm A')}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <h6>Payment Method:</h6>
                    <p>MPESA</p>
                  </div>
                </Col>
              </Row>

              <div className="mt-4">
                <h6>Invoiced Items:</h6>
                <Table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Cost Per Unit</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.items && invoiceData.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>{item.costPerUnit}</td>
                        <td>Ksh{item.totalAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {invoiceData.status === "Partially_Paid" && (
                <Row className="mt-4">
                  <Col xs="10" className="text-end">
                    <h6 className="mb-0">Balance:</h6>
                  </Col>
                  <Col xs="2" className="text-end">
                    <p className="mb-0">Ksh{calculateBalance()}</p>
                  </Col>
                </Row>
              )}

              <Row className="mt-2">
                <Col xs="10" className="text-end">
                  <h6 className="mb-0">Subtotal:</h6>
                </Col>
                <Col xs="2" className="text-end">
                  <p className="mb-0">Ksh{calculateSubtotal().toFixed(2)}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="10" className="text-end">
                  <h6 className="mb-0">Total Amount:</h6>
                </Col>
                <Col xs="2" className="text-end">
                  <p className="mb-0">Ksh{calculateTotal()}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceDetail;
