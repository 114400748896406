import { get, post } from './utils';

export async function fetchProductsApi(data) {
    return get(`/api/lookup/values?type=Product`, data);
}

export async function fetchCategoriesApi(data) {
    return get(`/api/lookup/values?type=Risk Category`, data);
}

export async function fetchRiskClassApi(data) {
    return get(`/api/lookup/values?type=Risk Category`, data);
} 

export async function fetchCoversApi(data) {
    return get(`/api/lookup/values?type=Policy Cover`, data);
}

export async function fetchAuditsApi(data) {
    return post(`/api/management/audits`, data);
}