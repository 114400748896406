import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  NavItem,
  NavLink,
  TabContent,
  FormFeedback,
  TabPane,
  Table,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../Claim/loadingSpinner";
import { useMutation, useQueryClient } from 'react-query';
import { getMemberByIdNumberApi } from "api/member";
import { getMemberPolicyApi } from 'api/policy'
import { requestPayment, checkPaymentStatus } from "api/transaction";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from 'sweetalert2';

const paymentPeriods = [
  { id: 1, name: 'Registration', value: 300 },
  { id: 2, name: '1 Month', value: 100 },
  { id: 3, name: '3 Months', value: 300 },
  { id: 4, name: '6 Months', value: 600 },
  { id: 5, name: '1 Year', value: 1200 }
]

const PaymentAdd = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [idNumber, setIdNumber] = useState(undefined);
  const [mobileNumber, setmobileNumber] = useState(undefined);
  const [amount, setAmount] = useState(100);
  const [member, setMember] = useState(null);
  const [search, setSearch] = useState(false);
  const queryClient = useQueryClient();
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const { mutate: mutatePolicies,
    isLoading: isLoadingPolicies, } = useMutation(
      (payload) => getMemberPolicyApi(payload),
      {
        onSettled: () => {
          queryClient.invalidateQueries('get-all-counties');
        },
      }
    );

  const onChangeIdNumber = (value) => {
    setMember(null);
    setIdNumber(value);
    setSearch(false);
  };

  const onChangeMobileNumber = (value) => {
    setmobileNumber(value);
  };

  const onChangeAmount = (e) => {
    const amt = Number(e.target.value);
    setAmount(amt);
    validation.setFieldValue('amount', amt);
  };

  useEffect(() => {
    mutatePolicies();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNumber: '',
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required("Please National ID Number/Passport"),
    }),
    onSubmit: async (values) => {
      const idNumber = values.idNumber;
      setIdNumber(idNumber);
      await getMemberByIdNumberApi(idNumber).then((member) => {
        setmobileNumber(member?.mobileNumber1?.replace(/\D/g, ''));
        setMember(member);
        setSearch(true);
      });
    },
  });

  const validation2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobileNumber: mobileNumber || '',
      amount: amount || ''
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().required("Please Enter Mobile Number"),
      amount: Yup.string().required("Please Enter Amount"),
    }),
    onSubmit: async (values) => {
      const mobile = mobileNumber.replace(/\D/g, '');
      const isRegistration = values.amount == 200;

      Swal.fire({
        title: 'Processing payment...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await handleRequestPayment({
        userId: member.memberId,
        clientMobile: mobile,
        description: isRegistration ? 'Sun Registration Payment' : "Sun Premium Payment",
        amount: values.amount,
        platform: "WEB",
        paymentMethod: "MPESA"
      }).then((res) => {
        console.log('Payment Response:', res);

        const { merchantRequestId, checkoutRequestID } = res.data.data;

        return requestPaymentStatus({
          merchantRequestId,
          checkoutRequestId: checkoutRequestID
        });
      }).then((statusRes) => {
        console.log('Payment Status Response:', statusRes.data.transactionStatus);

        const transactionStatus = statusRes.data.transactionStatus.toLowerCase();

        if (transactionStatus === 'success') {
          Swal.fire('Payment Successful!', 'Your payment was successful.', 'success');
        } else {
          Swal.fire('Payment Failed', 'Your payment could not be processed.', 'error');
        }
      }).catch((error) => {
        Swal.fire('Error', 'An error occurred while processing payment.', 'error');
        console.error('Payment Error:', error);
      });
    },
  });

  const handleRequestPayment = async (paymentData) => {
    try {
      setLoading(true);
      const response = await requestPayment(paymentData);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  const requestPaymentStatus = async (statusData) => {
    try {
      const response = await checkPaymentStatus(statusData);
      return response;
    } catch (error) {
      console.error('Payment Status Check Error:', error);
      throw error;
    }
  };

  const handleConfirmTransaction = () => {
    console.log(`Transaction ID: ${transactionId} confirmed`);
  };

  const stepStyle = {
    borderRadius: '50%',
    border: '2px solid #333',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  };

  //meta title
  document.title = "Add Payment | SUN Welfare Administration System";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Payments" breadcrumbItem="New Payment" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Make Payment</h4>
                  <Link to="/payments">
                    <Button type="button" color="primary" className="btn">
                      <i className="bx bx-arrow-back"></i> Back
                    </Button>
                  </Link>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}>
                            <span className="number"></span> Mpesa Express
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(1)}>
                            <span className="number"></span> Mpesa Standard
                          </NavLink>
                        </NavItem>

                        {/* <NavItem
                          className={classnames({ current: activeTab === 3 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(1)}>
                            <span className="number"></span> Card Payment
                          </NavLink>
                        </NavItem> */}
                      </ul>
                    </div>

                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body mx-auto" style={{ maxWidth: "50%" }}>
                        <TabPane tabId={1}>
                          {isLoadingPolicies && <LoadingSpinner />}
                          <Form
                            autoComplete="off"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-pancard-input5">
                                    Principal Member National ID Number
                                  </Label>
                                  <Input
                                    id="idNumber"
                                    name="idNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter principal ID number"
                                    onChange={(e) => {
                                      validation.handleChange(e);
                                      onChangeIdNumber(e.target.value);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.idNumber || ""}
                                    invalid={
                                      validation.touched.idNumber &&
                                        validation.errors.idNumber
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.idNumber &&
                                    validation.errors.idNumber ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.idNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {idNumber && search && !member && (
                              <h4 className="my-5 fs-5 text text-danger">
                                No member found with this ID Number
                              </h4>
                            )}

                            <Row>
                              <Col lg="4" sm="12" md="8">
                                <Button
                                  type="submit"
                                  color="primary"
                                  block
                                  className="mt-4 d-flex align-items-center justify-content-center"
                                >
                                  Search Member{" "}
                                  <i className="bx bx-search-alt search-icon fs-4 ms-2"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                          {member && (
                            <Row className="mt-5">
                              <Col>
                                <CardTitle className="h4">
                                  Principal Member
                                </CardTitle>
                                <p className="card-title-desc">
                                  Confirm that this is the member
                                  to send payment request to.
                                </p>

                                <div className="table-responsive">
                                  <Table className="table mb-0 table-striped">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>Mobile Number</th>
                                        <th>ID Number</th>
                                        <th>Policy Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>
                                          {`${member.firstName} ${member.middleName || ''
                                            } ${member.lastName}`}
                                        </td>
                                        <td>{member.mobileNumber1}</td>
                                        <td>
                                          {member.identificationNumber}
                                        </td>
                                        <td>{member.status}</td>
                                      </tr>
                                    </tbody>
                                  </Table>

                                  <CardTitle className="h4 mt-4">Request Payment</CardTitle>
                                  <Form autoComplete="off"
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation2.handleSubmit();
                                      return false;
                                    }}>
                                    <Row>
                                      <Col md="6">
                                        <FormGroup>
                                          <Label for="mobileNumber">Phone Number</Label>
                                          <Input
                                            type="number"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                              validation2.handleChange(e);
                                              onChangeMobileNumber(e.target.value);
                                            }}
                                            onBlur={validation2.handleBlur}
                                            value={mobileNumber || ""}
                                            invalid={
                                              validation2.touched.mobileNumber &&
                                                validation2.errors.mobileNumber
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation2.touched.mobileNumber && validation2.errors.mobileNumber ? (
                                            <FormFeedback type="invalid">{validation2.errors.mobileNumber}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label>Select Period</Label>
                                          <Input type='select'
                                            className="form-select"
                                            defaultValue={paymentPeriods.find(i => i.value === 100).value}
                                            name="amount"
                                            onChange={onChangeAmount}
                                            value={validation2.values.amount || ""}
                                            invalid={
                                              validation2.touched.amount && validation2.errors.amount ? true : false
                                            }
                                          >
                                            {
                                              paymentPeriods.map(item => (
                                                <option key={item.id} value={item.value}>
                                                  {item.name}
                                                </option>
                                              ))
                                            }
                                          </Input>
                                          {validation2.touched.gender && validation2.errors.gender ? (
                                            <FormFeedback type="invalid">{validation2.errors.gender}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row><h5> Amount to Pay Kes {validation.values.amount}</h5></Row>
                                    <FormGroup>
                                      <Button color="primary" block type="submit" disabled={loading}>
                                        {loading ? <Spinner size="sm" color="light" /> : 'Request Payment'}
                                      </Button>
                                    </FormGroup>
                                  </Form>
                                </div>
                                <div>
                                  <ol style={{ fontSize: '1.2em', listStyleType: 'none', paddingLeft: '0' }}>
                                    <li style={{
                                      position: 'relative',
                                      paddingLeft: '30px',
                                      marginBottom: '15px'
                                    }}>
                                      <span style={{
                                        position: 'absolute',
                                        left: '0',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: '#00b4e4',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                        1
                                      </span>
                                      You will shortly receive an M-PESA prompt on your phone requesting you to enter your M-PESA PIN to complete your payment.
                                    </li>
                                    <li style={{
                                      position: 'relative',
                                      paddingLeft: '30px',
                                      marginBottom: '15px'
                                    }}>
                                      <span style={{
                                        position: 'absolute',
                                        left: '0',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: '#00b4e4',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                        2
                                      </span>
                                      Please ensure your phone is on and unlocked to enable you to complete the process.
                                    </li>
                                    <li style={{
                                      position: 'relative',
                                      paddingLeft: '30px',
                                      marginBottom: '15px'
                                    }}>
                                      <span style={{
                                        position: 'absolute',
                                        left: '0',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: '#00b4e4',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                        3
                                      </span>
                                      Incase you experience difficulties receiving Mpesa express prompt you can also pay using Lipa na MPESA by using Mpesa Standard. Thank you.
                                    </li>
                                  </ol>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                        <TabPane tabId={2}>
                          <ol style={{ listStyle: 'none', padding: 0 }}>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>1</div>
                              Go to Safaricom Menu
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>2</div>
                              Select M-PESA
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>3</div>
                              Select Lipa na MPESA
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>4</div>
                              Select Paybill
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>5</div>
                              Enter Business No: 4068281
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>6</div>
                              Enter Account No: Your ID Number
                            </li>
                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <div style={stepStyle}>7</div>
                              Enter Amount
                            </li>
                          </ol>
                          <Input
                            type="text"
                            placeholder="Enter Transaction ID"
                            value={transactionId}
                            onChange={(e) => setTransactionId(e.target.value)}
                          />
                          <Button className="mt-4" color="primary" style={{ width: '100%' }} onClick={handleConfirmTransaction}>
                            Confirm
                          </Button>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Alert color="info" style={{ textAlign: 'center', marginTop: '20px' }}>
                            <h4 className="alert-heading">Coming Soon!</h4>
                            <p>
                              We're working card payments. Stay tuned for updates!
                            </p>
                          </Alert>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentAdd;