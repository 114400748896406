import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Button } from "reactstrap"


const FormWizard = () => {

  //meta title
  document.title="Form Wizard | SUN Welfare Administration System";

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 3) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Groups" breadcrumbItem="New Group" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-4">Add New Benefit type</h4>
                    <Link to="/groups">
                      <Button type="button" color="primary" className="btn">
                        <i className="bx bx-arrow-back"></i> Back
                      </Button>
                    </Link>
                  </div>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Group Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Add Group
                            Members
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                           <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">3.</span>Confirm
                            
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Group ID
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder="Enter Group ID"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Group Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Group Name"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-phoneno-input3">
                                   Group Facilitator Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input3"
                                    placeholder="Enter Group Facilitator Name"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Select Region</Label>
                                    <select className="form-select">
                                      <option defaultValue>
                                       Select Group Region
                                      </option>
                                      <option value="coast">Coast</option>
                                    <option value="nairobi">Nairobi</option>
                                    <option value="central">Central</option>
                                    <option value="eastern">Eastern</option>
                                    <option value="riftvalley">Rift Valley</option>
                                    <option value="western">Western</option>
                                    <option value="nyanza">Nyanza</option>
                                    <option value="northrift">North Eastern</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg="6">
                                <div className="mb-3">
                                  <Label>Select County</Label>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select Group County...
                                    </option>
                                    <option value="mombasa">Nairobi</option>
                                    <option value="kwale">Kwale</option>
                                    <option value="kilifi">Kilifi</option>
                                    <option value="tana-river">Tana River</option>
                                    <option value="lamu">Lamu</option>
                                    <option value="taita-taveta">Taita-Taveta</option>
                                    <option value="garissa">Garissa</option>
                                    <option value="wajir">Wajir</option>
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label>Select Constituency</Label>
                                  <select className="form-select">
                                    <option defaultValue>
                                      Select Group Constituency..
                                    </option>
                                    <option value="westlands">Westlands</option>
                                    <option value="kibra">Kibra</option>
                                    <option value="langata">Langata</option>
                                    <option value="dagoretti-north">Dagoretti North</option>
                                    <option value="dagoretti-south">Dagoretti South</option>
                                    <option value="kasarani">Kasarani</option>
                                    <option value="makadara">Makadara</option>
                                    <option value="embakasi-west">Embakasi West</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Select Ward</Label>
                                    <select className="form-select">
                                      <option defaultValue>
                                       Select Group Ward
                                      </option>
                                      <option value="VI">South C</option>
                                      <option value="MC">Gatina</option>
                                    </select>
                                  </div>
                                </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-pancard-input5">
                                      Member First Name
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-pancard-input5"
                                      placeholder="Enter First Name"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-vatno-input6">
                                      Member Last Name
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-vatno-input6"
                                      placeholder="Enter Last Name"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-pancard-input5">
                                     Select Date of Birth 
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="basicpill-pancard-input5"
                                      placeholder="Select Date of Birth"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Select Gender</Label>
                                    <select className="form-select">
                                      <option defaultValue>
                                        Select Member Gender
                                      </option>
                                      <option value="Female">Female</option>
                                      <option value="Male">Male</option>
                                      <option value="Intersex">Intersex</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                              <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-companyuin-input9">
                                      Enter Member SHOFCO ID 
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-companyuin-input9"
                                      placeholder="Enter Member SHOFCO ID"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-companyuin-input9">
                                      Phone Number 
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-companyuin-input9"
                                      placeholder="Enter Member Phone No."
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input10">
                                     Email (optional)
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-Declaration-input10"
                                      placeholder="xyz@gmail.com"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                     Enter National ID Number.
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Enter Member National ID"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Details</h5>
                                  <p className="text-muted">
                                    If your entries are correct, please submit.
                                  
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 3 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormWizard
